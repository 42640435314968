

<div *ngIf="chartOptions$ | async as chartOptions"
  echarts
  [options]="chartOptions"
  class="demo-chart"
  (chartLegendSelectChanged)="clickOnLegend($event)"
  (chartDataZoom)="timelineChanged($event)"
  (chartClick)="clickOnData($event)"></div>

<!-- <pre *ngIf="chartOptions$ | async as v">{{v | json}}</pre> -->

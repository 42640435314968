<div
  class="card mb-2 p-2 ml-2 text-white"
  [class.bg-success]="transaction.status"
  [class.bg-danger]="!transaction.status"
  style="position: relative">
  <button type="button" class="btn btn-secondary json-btn" (click)="toggleJSON()">JSON</button>
  <div><span class="font-weight-bold">Index: </span>{{transaction.transactionIndex}}</div>
  <div><span class="font-weight-bold">Hash: </span>{{transaction.transactionHash}}</div>
  <div><span class="font-weight-bold">From: </span>{{transaction.from}}</div>
  <div><span class="font-weight-bold">To: </span>{{transaction.to}}</div>
  <div><span class="font-weight-bold">Gas used: </span>{{prettyFormatGas(transaction.gasUsed)}}</div>
  <div><span class="font-weight-bold">Delay: </span>{{transaction.delay}}s</div>
  <div><span class="font-weight-bold">Gas price: </span>{{transaction.gasPriceGwei/1000000000}} Gwei</div>
  <!-- <div><span class="font-weight-bold">Cum. gas used: </span> {{prettyFormatGas(transaction.cumulativeGasUsed)}}</div> -->
  <div *ngIf="transaction.reason"><span class="font-weight-bold">Revert reason: </span>{{transaction.reason}}</div>
  <pre *ngIf="isJSON" class="bg-white">{{transaction | json}}</pre>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { setNavigationParameter } from 'src/shared/utils';
import { SelectorChoice, SELECTORS } from '../choice-selector/selectorDefinitions';



@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    console.log("CONST BLOCK")
  }
  ngOnInit(): void {
    this.init()
  }

  public choices = SELECTORS.timingBlocks;

  public faCalendar = faCalendar
  startTime$ = new BehaviorSubject<number>(0);
  endTime$ = new BehaviorSubject<number>(Date.now());
  interval$ = new BehaviorSubject<number>(0);

  // startTime: number = 0;
  // endTime: number = 0;

  get sTime() {
    let res = this.route.snapshot.queryParams["startTime"]
    return res ? res : '30m'
  }

  get eTIme() {
    let now = new Date();
    let res = this.route.snapshot.queryParams["endTime"]
    return res ? parseInt(res, 10) : Date.now()
  }

  changeTime(time: SelectorChoice) {
    this.interval$.next(time.value);
    console.log(time.value)
    this.startTime$.next(this.endTime$.value - time.value)
    setNavigationParameter(this.router, this.route, "startTime", time.id)
  }


  public init() {
    let d = new Date(0);
    d.setUTCSeconds(this.eTIme/1000);

    this.dateModel = {
      year: d.getFullYear(),
      month: d.getMonth()+1,
      day: d.getDate()
    }
    this.timeModel = {
      hour: d.getHours(),
      minute: d.getMinutes(),
      second: d.getSeconds()
    }

    this.endTime$.next(this.eTIme);
    this.startTime$.next(this.endTime$.value - this.interval$.value);
    // setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }


  public resetEndTime() {
    let now = new Date();
    this.dateModel = {
      year: now.getFullYear(),
      month: now.getMonth()+1,
      day: now.getDate()
    }
    console.log("month",this.dateModel.month)

    this.timeModel = {
      hour: now.getHours(),
      minute: now.getMinutes(),
      second: now.getSeconds()
    }
    this.endTime$.next(Date.now())
    this.startTime$.next(this.endTime$.value - this.interval$.value)
    setNavigationParameter(this.router, this.route, "endTime");
  }

  public onDateChange(event: any) {
    let end = new Date(event.year, event.month-1, event.day, this.timeModel.hour, this.timeModel.minute, this.timeModel.second)
    this.dateModel = event
    this.dateModel.month = event.month
    this.endTime$.next(end.getTime());
    this.startTime$.next(this.endTime$.value - this.interval$.value);
    setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  public onTimeChange(event: any) {
    let end = new Date(this.dateModel.year, this.dateModel.month-1, this.dateModel.day, event.hour, event.minute, event.second)
    this.timeModel = event;
    this.endTime$.next(end.getTime());
    this.startTime$.next(this.endTime$.value - this.interval$.value);
    setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  timeModel: any = null;
  dateModel: any = null;

  event!: any;

  public receiveEvent(event: any) {
    this.event = event;
  }

}

import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSgbInteger'
})
export class FormatSgbIntegerPipe implements PipeTransform {

  transform(value: any, decimals = 0): any {
    if (!value || value.length == 0) return null
    let cutWei = value.slice(0, -(18-decimals))
    if(cutWei.length == 0) return "0"
    let val = parseInt(cutWei, 10)/ Math.pow(10, decimals);
    return formatCurrency(val, "en-US", "", "", `1.${decimals}-${decimals}`)
  }

}

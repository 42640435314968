import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, shareReplay, switchMap, take } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { BlockInfo } from 'src/api/model/blockInfo';
import { DD, prettyFormatGas } from 'src/shared/utils';


@Component({
  selector: 'app-blocks-chart',
  templateUrl: './blocks-chart.component.html',
  styleUrls: ['./blocks-chart.component.scss']
})
export class BlocksChartComponent implements OnInit, OnDestroy {

  @Input() set startTime(value: number | null) {
    this.xAxisZoom.start = 0;
    this.xAxisZoom.end = 100;
    this.startTime$.next(value || 0)
  }

  @Input() set endTime(value: number | null) {
    this.endTime$.next(value || 0)
  }

  @Output() eventSelected = new EventEmitter();

  constructor(
    private ftsoService: FTSOService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }
  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  sub: Subscription | null = null;

  ngOnInit(): void {
  }

  startTime$ = new BehaviorSubject<number>(0);
  endTime$ = new BehaviorSubject<number>(0);

  chartOptions$ = combineLatest([this.startTime$, this.endTime$])
    .pipe(
      filter(pair => !!pair[0]),
      switchMap((pair: any) => this.blockOptions(new Date(pair[0]), new Date(pair[1]))),
      shareReplay(1)
    )

  async timeout(prom: any, time: number) {
    return Promise.race([prom, new Promise((_r, rej) => setTimeout(rej, time))]);
  }

  async blockOptions(startTime: Date, endTime?: Date) {
    let response = await this.ftsoService.getBlockInfo(undefined, undefined, startTime ? Math.floor(startTime.getTime() / 1000) : undefined, endTime ? Math.floor(endTime.getTime() / 1000) : undefined).pipe(take(1)).toPromise();
    let data = response.data as BlockInfo[];
    let boundaries = [];
    try {
      let configResponse;
      try {
        configResponse = await this.timeout(this.ftsoService.getPriceEpochConfiguration().pipe(take(1)).toPromise(), 2000);
      } catch (e) {
        // configResponse = await this.ftsoService.blockOnlyConfig().pipe(take(1)).toPromise();
        if (!configResponse.data) {
          throw e;
        }
      }
      let { firstPriceEpochStartTs, priceEpochDurationSeconds, revealEpochDurationSeconds } = configResponse.data;

      let now = Math.floor(Date.now() / 1000);
      let earliest = Math.min(...data.map(x => x.timestamp));
      let lowestId = Math.floor((earliest - firstPriceEpochStartTs) / priceEpochDurationSeconds);
      let highestId = Math.floor((now - firstPriceEpochStartTs) / priceEpochDurationSeconds);

      for (let i = lowestId; i <= highestId; i++) {
        let time = firstPriceEpochStartTs + i * priceEpochDurationSeconds;
        boundaries.push([{ name: "", xAxis: new Date(time * 1000) }, { xAxis: new Date((time + revealEpochDurationSeconds) * 1000) }]);
      }
    } catch (e) {
      console.log(e)
    }
    // console.log("boundaries", boundaries)
    let colors = ['#5470C6', '#91CC75', '#FAC858', "#EE6666", "#73C0DE"];

    let test: string = "Test"
    let options: EChartsOption = {
      // title: {
      //   text: `Block info`,
      // },
      grid: {
        left: '11%'
      },
      xAxis: {
        type: 'time',
      },
      yAxis: [
        {
          type: 'value',
          name: "Gas used",
          min: 'dataMin',
          max: 'dataMax',
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          axisLabel: {
            formatter: (value: any, index: number) => prettyFormatGas(value)
          }
        },
        {
          type: 'value',
          name: "TX\n count",
          min: 'dataMin',
          max: 'dataMax',
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
        },
        {
          type: 'value',
          name: "AVG TX\n gas",
          min: 'dataMin',
          max: 'dataMax',
          offset: 50,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[2]
            }
          },
          axisLabel: {
            formatter: (value: any, index: number) => prettyFormatGas(value)
          }
        },
        {
          type: 'value',
          name: "Block",
          min: 'dataMin',
          max: 'dataMax',
          offset: 60,
          position: 'left',
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[3]
            }
          }
        },
      ],
      toolbox: {
        feature: {
          dataView: { show: true, readOnly: true },
          // magicType: { show: true, type: ['line', 'bar'] },
          // restore: { show: true },
          saveAsImage: { show: true, name: "PNG" }
        }
      },
      legend: {
        data: ["Gas used", "TX count", "AVG TX gas", "Block"],
        selected: this.selected
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        },
      },
      dataZoom: [
        {
          type: 'slider',
          ...this.xAxisZoom
        },
        {
          type: 'inside',
          ...this.xAxisZoom
        }
      ],
      series: [
        {
          name: "Gas used",
          type: 'bar',
          data: data.map(entry => {
            let time = new Date(entry.timestamp * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                entry.gasUsed,
                entry.number
              ]
            } as any
          })
        },
        {
          type: 'bar',
          name: "TX count",
          data: data.map(entry => {
            let time = new Date(entry.timestamp * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                entry.txCount,
                entry.number
              ]
            } as any
          })
        },
        {
          type: 'bar',
          name: "AVG TX gas",
          data: data.map(entry => {
            let time = new Date(entry.timestamp * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                Math.floor(entry.gasUsed / entry.txCount),
                entry.number
              ]
            } as any
          }),
          yAxisIndex: 2
        },
        {
          type: 'bar',
          name: "Block",
          data: data.map(entry => {
            let time = new Date(entry.timestamp * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                entry.number,
                entry.number
              ]
            } as any
          }),
          yAxisIndex: 3
        },
        {
          type: 'bar',
          name: "Fake",
          tooltip: {
            show: false
          },
          data: data.map(entry => {
            let time = new Date(entry.timestamp * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                0
              ]
            } as any
          }),
          markArea: {
            itemStyle: {
              color: 'rgba(255, 173, 177, 0.2)'
            },
            data: boundaries as any
          }
        }
      ],
    };
    return options;
  }



  public clickOnData(event: any) {
    if (event.componentType === 'series') {
      let blockNumber = event.value[2];
      this.router.navigate(['block', blockNumber])
    }
    if (event.componentType === 'markPoint') {
      this.eventSelected.emit(event.data.payload)
    }
  }

  private selected: any = {
    "Gas used": true,
    "TX count": true,
    "AVG TX gas": true,
    "Block": false,
    "Pending TXs": false
  }

  private xAxisZoom: any = {};

  public clickOnLegend(event: any) {
    this.selected = { ...event.selected };
    delete this.selected["undefined"]
  }

  public timelineChanged(event: any) {
    if (event.batch && event.batch.length > 0) {
      this.xAxisZoom.start = event.batch[0].start;
      this.xAxisZoom.end = event.batch[0].end;

    } else {
      this.xAxisZoom.start = event.start;
      this.xAxisZoom.end = event.end;
    }
  }

}

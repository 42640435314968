

<!-- <pre>{{chartOptions$ | async | json}}</pre> -->
<div
  *ngIf="chartOptions$ | async as chartOptions; else noData"
  echarts
  [options]="chartOptions"
  (chartClick)="clickOnData($event)"
  (chartLegendSelectChanged)="clickOnLegend($event)"
  (chartDataZoom)="timelineChanged($event)"
  class="demo-chart"></div>
<ng-template #noData>
  <div class="text-center font-weight-bold no-data">No data
  </div>
</ng-template>

import { ThisReceiver } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/api/api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenActivationGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  canAct = false;
  token!: string;
  counter: number = 0;
  inDb!: boolean;
  tokenLocalStorage!: string;

  // check if session is in database
  async sessionInDb(ID: string) {
    let response = await this.authService.sessionInDatabase(ID).pipe(take(1)).toPromise();
    let data = response.data;
    return data
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // DISABLING PASSWORD
    return true;

    this.token = route.queryParams["token"];
    this.tokenLocalStorage = localStorage.getItem("token") as string;
    if (this.token != undefined) {
      // Delete the token from parameters
      this.inDb = await this.sessionInDb(this.token); //true if token is in database
      if (this.inDb) {
        this.canAct = true;
        localStorage.setItem("token", this.token);
      }
      else {
        this.canAct = false;
        this.router.navigate(['access-denied']);
      }
    }

    else if (localStorage.getItem("token") != undefined) {

      // this.inDb = await this.sessionInDb(this.tokenLocalStorage); //true if token in localStorage is in database
      // if (!this.inDb) {
      //   this.canAct = false;
      //   localStorage.removeItem("token");
      //   this.router.navigate(['access-denied']);
      // }
      // else {
      //   this.canAct = true;
      // }
      this.canAct = true;
    }

    else {
      this.canAct = false;
      this.router.navigate(['access-denied']);
    }
    return this.canAct
  }
}


<!-- <app-tab-nav choice="block"></app-tab-nav> -->
<div class="container pt-3" style="height: 100vh">
  <div class="d-flex flex-column justify-content-center align-items-center" style="height:
    100vh">
    <form>
      <h2>Invalid credentials</h2>
      <div class="form-group">
        <label for="token">Enter access token</label>
        <input type="text" [(ngModel)]="token" class="form-control" id="token" name="token" type="password">
      </div>
      <button type="submit" class="btn btn-primary" (click)="submit()">Submit</button>
    </form>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { ApiResponseDataProviderStatsArray } from 'src/api/model/apiResponseDataProviderStatsArray';
import { DataProvidersInfo, sleepms } from 'src/shared/utils';
import { CurrencyConfigService } from '../services/currency-config.service';
import { GlobalEventManagerService } from '../system/global-event-manager.service';


@Component({
  selector: 'app-data-providers',
  templateUrl: './data-providers.component.html',
  styleUrls: ['./data-providers.component.scss']
})
export class DataProvidersComponent implements OnInit {

  constructor(
    private ftsoService: FTSOService,
    private router: Router,
    private globalEventsManager: GlobalEventManagerService,
    private configurationService: CurrencyConfigService
  ) { }

  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  dtOptions = {
    paging: false,
    responsive: true,
    order: [[5, "desc"]],
    rowCallback: (row: Node, data: any[] | object, dataIndex: number) => {
      row.childNodes[0].textContent = String(dataIndex + 1);
    },
    columnDefs: [{
      targets: [0, 1, 2, 17],
      orderable: false
    }]
    // scrollX: true
  }
  dtTrigger: Subject<any> = new Subject();
  lastRowsToShow: any;

  ngOnInit(): void {
    this.dataProvidersInfo();
  }

  public checkBoxes = new FormArray([]);

  public listedOnly = true;

  providersOutput: DataProvidersInfo[] = []

  public get currency() {
    return this.configurationService.networkCurrency;
  }

  async dataProvidersInfo() {
    this.globalEventsManager.showLoading(true);
    let response!: ApiResponseDataProviderStatsArray;
    // wait for response;
    let delay = 1000;
    let backoff = 1.5;
    let count = 5;
    while (true) {
      if (count <= 0) {
        throw new Error("Bad connection");
      }
      response = await this.ftsoService.getDataProviders().pipe(take(1)).toPromise();
      if (response) {
        break;
      }
      await sleepms(delay);
      delay *= backoff;
      count--;
    }
    let data = response.data as DataProvidersInfo[];


    this.providersOutput = data;
    this.providersOutput.forEach(val => {
      this.checkBoxes.push(new FormGroup({
        data: new FormControl(val),
        checked: new FormControl(false)
      }))
    })
    this.dtTrigger.next();
    this.globalEventsManager.showLoading(false);
    // console.log(data)
  }

  get rowsToShow(): FormArray {
    let toShow = new FormArray([]);
    for (let control of this.checkBoxes.controls) {
      if (!this.listedOnly || control.get("data")?.value.listed) {
        toShow.push(control);
      }
    }
    this.lastRowsToShow = toShow;
    return toShow;
  }

  public makeCompare() {
    let selectedAddresses = [];
    for (let control of this.lastRowsToShow.value) {
      if (control.checked) {
        selectedAddresses.push(control.data?.address)
      }
    }
    // if(selectedAddresses.length === 0 || selectedAddresses.length > 5) return;
    if (selectedAddresses.length == 0) return;
    this.router.navigate(['price'], {
      queryParams: {
        currency: 'XRP',
        startTime: '30m', providerAddress: selectedAddresses.join(",")
      }
    })
  }

  public get checkboxCount() {
    let cnt = 0;
    for (let control of this.checkBoxes.value) {
      if (control.checked) {
        cnt++;
      }
    }
    return cnt;
  }

  public clearCheckboxes() {
    for (let control of this.checkBoxes.controls) {
      let ctrl = control.get("checked") as FormControl;
      ctrl.setValue(false);
      ctrl.updateValueAndValidity()
    }
  }

  public selectAllCheckboxes() {
    for (let control of this.checkBoxes.controls) {
      let ctrl = control.get("checked") as FormControl;
      ctrl.setValue(true);
      ctrl.updateValueAndValidity()
    }
    // console.log(this.checkBoxes)
  }

  public rewardRate(row: any) {
    let res = (parseFloat(row.totalEpochReward) / parseFloat(row.currentRewardEpochVotePower) * (10000 - parseInt(row.fee.fee)) / 100).toFixed(3)
    if (res === "NaN") return "0"
    if (res === "0.000") return "0"
    return res
  }

  public rewardRatePrevious(row: any) {
    let res = (parseFloat(row.previousRewardEpoch.reward) / parseFloat(row.previousRewardEpoch.votePower) * (10000 - parseInt(row.previousRewardEpoch.fee)) / 100).toFixed(3)
    if (res === "NaN") return "0"
    if (res === "0.000") return "0"
    return res
  }

  public async toggleListedOnly(event: any) {
    this.listedOnly = event;
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  public getCurrentDelegationShare(row: any) {
    if (row.currentRewardEpoch) {
      return Math.round(parseFloat(row.currentRewardEpoch.votePower) / parseFloat(row.currentRewardEpoch.circulatingSupply) * 10000) / 100;
    }
    return 0;
  }

  get rewardEpoch() {
    return this.checkBoxes?.controls?.[0]?.value?.data?.currentRewardEpoch?.rewardEpochId
  }

  get timeToNextRewardEpoch() {
    let currentRewardEpoch = this.checkBoxes?.controls?.[0]?.value?.data?.currentRewardEpoch;
    let previousRewardEpoch = this.checkBoxes?.controls?.[0]?.value?.data?.previousRewardEpoch;
    if (!currentRewardEpoch || !previousRewardEpoch) {
      return 0;
    }
    let now = Math.round(Date.now() / 1000)
    let duration = currentRewardEpoch.startTimestamp - previousRewardEpoch.startTimestamp;
    let expired = now - currentRewardEpoch.startTimestamp;
    return duration - expired;
  }

  get totalVotePower() {
    return this.checkBoxes?.controls?.[0]?.value?.data?.currentWnatVotePower
  }

  currentDelegation(row: any) {
    return Math.round(parseFloat(row.currentVotePower) / parseFloat(row.currentWnatVotePower) * 10000) / 100;
  }

  activeDelegation(row: any) {
    return Math.round(parseFloat(row.currentRewardEpoch.votePower) / parseFloat(row.currentRewardEpoch.wnatVotePower) * 10000) / 100;
  }

  statusForValue(value: number) {
    if (value >= 2.5) {
      return "text-danger";
    }
    if (value >= 2) {
      return "text-warning";
    }
    return "text-success";
  }

  currentDelegationClass(row: any) {
    return this.statusForValue(this.currentDelegation(row))
  }

  activeDelegationClass(row: any) {
    return this.statusForValue(this.activeDelegation(row))
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DD } from 'src/shared/utils';

@Component({
  selector: 'app-selected-event',
  templateUrl: './selected-event.component.html',
  styleUrls: ['./selected-event.component.scss']
})
export class SelectedEventComponent implements OnInit {

  constructor() { }

  showEvent = false;
  _event: any;
  @Input() set event(value: any) {
    this._event = value;
    if(value) {
      this.showEvent = false;
      setTimeout(() => {this.showEvent = true}, 10)
    }
  }

  get event() {
    return this._event;
  }

  ngOnInit(): void {
  }

  get timestamp() {
    if(!this.event) return null;
    let time = new Date(this.event!.timestamp*1000)
    return `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`
  }
}

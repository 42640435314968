
<app-tab-nav choice="stats"></app-tab-nav>

<div class="container pt-3">

  <div class="row mt-3">
    <app-selected-event [event]="event"></app-selected-event>
  </div>


  <div class="card mb-2 p-1">
    <app-stats-chart
      [startTime]="startTime$ | async"
      [endTime]="endTime$ | async"
      [averagingPeriodSeconds]="averagingPeriodSeconds$ | async"
      (eventSelected)="receiveEvent($event)"></app-stats-chart>
  </div>
  <div class="d-flex justify-content-between">
    <div style="font-size: 0.6rem;"></div>
    <app-choice-selector (selected)="changeTime($event)"
      [initialChoiceTimeId]="sTime"></app-choice-selector>
  </div>
  <div>
    <!-- <input type="text" ngbDatepicker (dateSelect)="onDateSelect($event)"/> -->
    <div class="row">
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <label class="mr-1"><b>End time:</b></label>
            <input class="form-control" placeholder="yyyy-mm-dd"
              name="dp" [ngModel]="dateModel" ngbDatepicker
              (ngModelChange)="onDateChange($event)" #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar"
                (click)="d.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </form>

      <ngb-timepicker class="ml-3" [ngModel]="timeModel"
        (ngModelChange)="onTimeChange($event)"></ngb-timepicker>
      <div class="d-flex flex-column justify-content-center">
        <button type="button" class="btn btn-primary ml-3"
          (click)="resetEndTime()">Reset to now</button>
      </div>

    </div>

    <!-- <div class="card mb-2 p-1">
      <app-stats-chart [averagingPeriodSeconds]="averagingPeriodSeconds$ | async"></app-stats-chart>
    </div> -->
    <div class="row">
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <label class="mr-1"><b>Averaging period seconds:</b></label>

            <input type="number" min="0" class="form-control"
              placeholder="{{averagingPeriodSeconds$.value}}" matInput
              name="value" [(ngModel)]="secondsModel">
          </div>
        </div>
      </form>
      <div class="d-flex flex-column justify-content-center">
        <button type="button" class="btn btn-primary ml-3"
          (click)="changeAveragingPeriodSeconds()">Reset chart</button>
      </div>
    </div>
  </div>

</div>

 <!-- <pre>{{show | json}}</pre> -->
 <div *ngIf="show"
  class="btn-group btn-group-toggle d-flex flex-wrap"
  ngbRadioGroup
  [name]="name"
  [(ngModel)]="_selected"
  (ngModelChange)="select($event)">
  <label
    *ngFor="let choice of choices; index as i"
    [class.btn-primary]="!outline"
    [class.btn-outline-primary]="outline"
    ngbButtonLabel>
    <input type="radio" ngbButton [value]="choice"> {{choice.label}}
  </label>
</div>

<div *ngIf="showTimes"
class="btn-group btn-group-toggle d-flex flex-wrap"
ngbRadioGroup
[name]="name"
[(ngModel)]="_selected"
(ngModelChange)="select($event)">
<label
  *ngFor="let choice of timeChoices; index as i"
  [class.btn-primary]="!outline"
  [class.btn-outline-primary]="outline"
  ngbButtonLabel>
  <input type="radio" ngbButton [value]="choice"> {{choice.label}}
</label>
</div>


<app-tab-nav choice="block"></app-tab-nav>

<div class="container pt-3">
  <div class="row mt-3">
    <app-selected-event [event]="event"></app-selected-event>
  </div>

  <div class="card mb-2 p-1">
    <app-blocks-chart
      [startTime]="startTime$ | async"
      [endTime]="endTime$ | async"
      (eventSelected)="receiveEvent($event)"></app-blocks-chart>
  </div>
  <div class="d-flex justify-content-between">
    <div style="font-size: 0.6rem; margin-bottom: -1000px;">*Click on any bar
      for block details</div>
    <app-choice-selector (selected)="changeTime($event)"
      [initialChoiceTimeId]="sTime" [choices]="choices"></app-choice-selector>
  </div>
  <div>
    <!-- <input type="text" ngbDatepicker (dateSelect)="onDateSelect($event)"/> -->
    <div class="row">
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <label class="mr-1"><b>End time:</b></label>
            <input class="form-control" placeholder="yyyy-mm-dd"
              name="dp" [ngModel]="dateModel" ngbDatepicker
              (ngModelChange)="onDateChange($event)" #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar"
                (click)="d.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </form>

      <ngb-timepicker class="ml-3" [ngModel]="timeModel"
        (ngModelChange)="onTimeChange($event)"></ngb-timepicker>
      <div class="d-flex flex-column justify-content-center">
        <button type="button" class="btn btn-primary ml-3"
          (click)="resetEndTime()">Reset to now</button>
      </div>
    </div>

  </div>
</div>

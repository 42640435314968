import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { SuccessStats } from 'src/api/model/successStats';
import { ColorsData, DataProvidersInfo, setNavigationParameter, Stats } from 'src/shared/utils';
import { SelectorChoice, SELECTORS } from '../choice-selector/selectorDefinitions';
import { CurrencyConfigService } from '../services/currency-config.service';

interface addressNameLogo {
  address: string | undefined;
  name: string | undefined;
  logo: string | undefined;
}
@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})


export class PriceComponent {

  public faCalendar = faCalendar;
  public relative = false;
  // @Input() set stats(value: Stats) {
  //   this.stats$.next(value)
  // }

  constructor(
    private currencyConfigService: CurrencyConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private ftsoService: FTSOService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.voterAddress$.next(this.address);
    this.relative = this.route.snapshot.queryParams["relative"] === "true";
    setNavigationParameter(this.router, this.route, "providerAddress", String(this.voterAddress$.value));
    this.getProviderData(this.voterAddress$.value[0]);
    this.init()
    this.syncExchanges()
  }

  async syncExchanges() {
    let res = await this.ftsoService.getExchangesForCurrency(this.currency).pipe(take(1)).toPromise();
    this.exchanges = res.data.map((exchange: string) => {
      return {
        id: exchange,
        label: exchange,
        value: exchange
      } as SelectorChoice
    })
    // if(this.exchanges.length) {
    //   setTimeout(() => {this.selectExchange(this.exchanges[0])})
    // }

  }

  startTime$ = new BehaviorSubject<number>(0);
  endTime$ = new BehaviorSubject<number>(Date.now());
  interval$ = new BehaviorSubject<number>(0);

  voterAddress$ = new BehaviorSubject<string[]>([]);

  get currency() {
    let res = this.route.snapshot.queryParams["currency"];
    return res ? res : 'XRP'
  }

  get selectedExchange() {
    let res = this.route.snapshot.queryParams["exchange"];
    return res;
  }

  get sTime() {
    let res = this.route.snapshot.queryParams["startTime"]
    return res ? res : '30m'
  }


  get eTime() {
    let res = this.route.snapshot.queryParams["endTime"]
    return res ? parseInt(res, 10) : Math.floor(Date.now()/1000);
  }


  get address() {
    let res = this.route.snapshot.queryParams["providerAddress"];
    if (res) {
      res = res.split(",");
      // res = res.slice(0,5);
    }
    // console.log("res, stats", res)
    return res ? res : ""
  }

  public choices = this.currencyConfigService.currencySelections;
  public exchanges: SelectorChoice[] = []
  // startTime: number = 0;
  addressModel: string[] = [];
  public timeChoices = SELECTORS.timingPrices;

  goTo(selection: SelectorChoice) {
    setNavigationParameter(this.router, this.route, "currency", selection.id);
  }

  selectExchange(selection: SelectorChoice) {
    setNavigationParameter(this.router, this.route, "exchange", selection.id);
  }

  // changeTime(time: SelectorChoice) {
  //   let endTime = this.eTime*1000 || Date.now()
  //   this.startTime = endTime - time.value;
  //   setNavigationParameter(this.router, this.route, "startTime", time.id);
  // }

  changeTime(time: SelectorChoice) {
    this.interval$.next(time.value);
    this.startTime$.next(this.endTime$.value - Math.floor(time.value/1000))
    setNavigationParameter(this.router, this.route, "startTime", time.id)
  }


  public changeVoterAddress() {
    this.voterAddress$.next(this.addressModel);
    setNavigationParameter(this.router, this.route, "providerAddress", String(this.voterAddress$.value));
    this.getProviderData(this.voterAddress$.value[0]);
  }

  statsOutput: SuccessStats[] = [];
  expected = undefined;
  availability = undefined;
  colors: ColorsData[] = [];

  getStats(stats: SuccessStats[]) {
    stats.sort((a: SuccessStats, b: SuccessStats) => a.address < b.address ? -1 : (a.address > b.address ? 1 : 0));
    this.statsOutput = stats;
  }

  getExpected(expectedAll: any) {
    this.expected = expectedAll;
  }

  getAvailability (availability: any) {
    this.availability = availability;
  }

  getColors (colorsData: any) {
    colorsData.sort((a: ColorsData, b: ColorsData) => a.address < b.address ? -1 : (a.address > b.address ? 1 : 0));
    this.colors = colorsData;
  }


  dataDisplay: addressNameLogo = {
    address: "",
    name: "",
    logo: ""
  };


  public async getProviderData(providerAddress: string) {
    let response = await this.ftsoService.getDataProviders().pipe(take(1)).toPromise();
    let data = response.data as DataProvidersInfo[];
    let providerData = data.find(provider => {
      return provider.address == providerAddress
    });
    this.dataDisplay = {
      address: providerData?.address,
      name: providerData?.name,
      logo: providerData?.logoURI
    }

  }

  public toggleRelative(event: any) {
    setNavigationParameter(this.router, this.route, "relative", event.toString());
  }

  public init() {
    let d = new Date(0);
    d.setUTCSeconds(this.eTime);

    this.dateModel = {
      year: d.getFullYear(),
      month: d.getMonth()+1,
      day: d.getDate()
    }
    this.timeModel = {
      hour: d.getHours(),
      minute: d.getMinutes(),
      second: d.getSeconds()
    }

    this.endTime$.next(this.eTime);
    this.startTime$.next(this.endTime$.value - Math.floor(this.interval$.value/1000));
    // setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }


  public resetEndTime() {
    let now = new Date();
    this.dateModel = {
      year: now.getFullYear(),
      month: now.getMonth()+1,
      day: now.getDate()
    }

    this.timeModel = {
      hour: now.getHours(),
      minute: now.getMinutes(),
      second: now.getSeconds()
    }
    this.endTime$.next(Math.floor(Date.now()/1000));
    this.startTime$.next(this.endTime$.value - Math.floor(this.interval$.value/1000))
    setNavigationParameter(this.router, this.route, "endTime");
  }

  public onDateChange(event: any) {
    let end = new Date(event.year, event.month-1, event.day, this.timeModel.hour, this.timeModel.minute, this.timeModel.second)
    this.dateModel = event
    this.dateModel.month = event.month
    this.endTime$.next(Math.floor(end.getTime()/1000));
    this.startTime$.next(this.endTime$.value - Math.floor(this.interval$.value/1000));
    setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  public onTimeChange(event: any) {
    let end = new Date(this.dateModel.year, this.dateModel.month-1, this.dateModel.day, event.hour, event.minute, event.second)
    this.timeModel = event;
    this.endTime$.next(Math.floor(end.getTime()/1000));
    this.startTime$.next(this.endTime$.value - Math.floor(this.interval$.value/1000));
    setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  timeModel: any = null;
  dateModel: any = null;

  public coinLabel(label: string) {
    let newLabel = label.toUpperCase();
    if(newLabel.startsWith("FTSO")) {
      newLabel = newLabel.slice(4);
    }
    if(newLabel.startsWith("TEST")) {
      newLabel = newLabel.slice(4);
    }
    return newLabel;
  }
}

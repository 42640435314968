import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        public route: ActivatedRoute,
        private router: Router
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    try {
                        if (err.status == 401) {
                            localStorage.removeItem("token");
                            this.router.navigate(['access-denied']);
                        } else if (err.status === 403) {
                            localStorage.removeItem("token");
                            this.router.navigate(['access-denied']);
                        }
                    } catch (e) { }
                    finally { }
                }
                return of(err);
            })
        );
    }


}


<app-tab-nav choice="config"></app-tab-nav>

<div class="container pt-3">
  <div class="card mb-2 p-1">
    <pre *ngIf="data$ | async as data">{{data | json}}</pre>
    <!-- <app-price-votes [currency]="currency" [startTime]="startTime"></app-price-votes> -->
  </div>
  <div class="d-flex justify-content-between">
    <!-- <app-choice-selector [choices]="choices" (selected)="goTo($event)" [initialChoiceId]="currency"></app-choice-selector>
    <app-choice-selector (selected)="changeTime($event)" [startIndex]="2"></app-choice-selector> -->
  </div>
</div>

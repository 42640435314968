
<app-tab-nav choice="collusion"></app-tab-nav>

<!-- <div class="container pt-3" style="height: 100vh" > -->

<!-- <div class="card mb-2 p-1" style="height: 100%"> -->
<!-- <pre *ngIf="data$ | async as data">{{data | json}}</pre> -->

<!-- </div> -->

<!-- </div> -->
<div class="row">

  <div class="col-sm input-group mb-1">
    <label class="mr-3 mt-1 ml-3" for="search"><b>Search:</b></label>
    <input id="search" class="form-control"
      placeholder="a-ftso, ftso.it, alpha ..."
      matInput
      [ngModel]="search"
      (ngModelChange)="onSearchChange($event)">
  </div>

  <div class="col-sm input-group mb-1">
    <label class="mr-3 mt-1 ml-3" for="threshold"><b>Threshold:</b></label>
    <input id="threshold" type="number" min="0.7" step="0.001" max="1"
      class="form-control"
      [(ngModel)]="threshold"
      (ngModelChange)="onThresholdChange($event)">
  </div>

  <div class="col-sm mb-1 ml-3">
    <button type="button" class="btn btn-primary"
      (click)="compareProviders()">Compare {{selectedNodesCount ? "(" + selectedNodesCount+ ")" : ""}}</button>
  </div>

</div>
<div *ngIf="!showNetwork" style="height: 70vh" class="d-flex justify-content-center align-items-center">
  <div>Calculating (may take up to a minute)...</div>
</div>

<div #network [ngStyle]="{'height': showNetwork ? '70vh' : '0vh'}">
</div>


<div class="row col ml-1">
  <form class="form-inline">
    <div class="form-group">
      <div class="input-group">
        <label class="mr-1"><b>End time:</b></label>
        <input class="form-control" placeholder="yyyy-mm-dd"
          name="dp" [ngModel]="dateModel" ngbDatepicker
          (ngModelChange)="onDateChange($event)" #d="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar"
            (click)="d.toggle()" type="button">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </form>

  <!-- <ngb-timepicker class="ml-3" [ngModel]="timeModel"
    (ngModelChange)="onTimeChange($event)"></ngb-timepicker>
  <div class="d-flex flex-column justify-content-center">
    <button type="button" class="btn btn-primary ml-3"
      (click)="resetEndTime()">Reset to now</button>
  </div> -->
  <!-- <div class="d-flex flex-column justify-content-center">
    <button type="button" class="btn btn-primary ml-3"
      (click)="reload()">Reload</button>
  </div> -->

</div>



<!-- <html> -->
<!-- <div>
      Filter: <input id="filter" type="text" onchange="onChange(this)">
  </div>
  <div>
      Threshold: <input id="threshold" type="text" onchange="onThresholdChange(this)">
  </div>
  <div>
    <button onclick="redirectToFtsoMonitor()">View in FTSO Monitor</button>
  </div> -->
<!-- <div id="network"></div> -->

<!-- <script type="text/javascript">

      // create an array with nodes
      let rawNodes = ${cleanIt(nodes)};
      var nodes = new vis.DataSet(rawNodes);
      const edgeWeights = ${cleanIt(edgeWeights)};
      // create an array with edges
      var edges = new vis.DataSet(${cleanIt(edges)});

      let threshold = 0.95;
      var thresholdValue;


      const edgesFilter = (edge) => {
        return edge.value >= thresholdValue;
      };

      const nodesView = new vis.DataView(nodes);
      const edgesView = new vis.DataView(edges, { filter: edgesFilter });

      onThresholdChange();
      // create a network
      var container = document.getElementById('mynetwork');

      // provide the data in the vis format
      var data = {
          nodes: nodesView,
          edges: edgesView
      };
      var options = {
        nodes: {
          shape: "dot",
          color:{
            highlight: '#FF0000'
          }
        },
        physics:{
           stabilization: false,
           solver: "forceAtlas2Based"
        },
        interaction: { multiselect: true}
      };

      // initialize your network!
      var network = new vis.Network(container, data, options);
      function selectNodeWithText(text) {
           let lowerText = text.toLowerCase();
           let node = rawNodes.find(x => x.label.toLowerCase().indexOf(lowerText) >= 0)
           if(node) {
              network.selectNodes([node.id])
           }
      }

      function onChange(event) {
         if(event.value) {
           selectNodeWithText(event.value);
         }
      }

      function onThresholdChange(event) {
        if(!event || !event.value) {
            threshold = 0.95;
            document.getElementById("threshold").value = threshold;
        } else {
              let val = parseFloat(event.value);
              if(val >= 0.7 && val <= 1) {
                 threshold = event.value;
              }
         }
         const cutoffIndex = Math.floor(edgeWeights.length * threshold);
         thresholdValue = edgeWeights[cutoffIndex];
         edgesView.refresh();
      }

      function redirectToFtsoMonitor() {
         if(selectedNodes && selectedNodes.length) {
            let addresses = selectedNodes.map(x => x.label.slice(0,42).trim()).join(",")
           //  let url = "https://ftso-monitor.flare.network/price?currency=XRP&startTime=30m&token=SongBird:Fly&providerAddress=" + addresses;
           let url = "http://localhost:4200//price?currency=XRP&startTime=30m&token=SongBird:Fly&providerAddress=" + addresses;
            window.open(url, '_blank').focus();
         }

      }

      var selectedNodes =
      network.on( 'click', (properties) => {
        var ids = properties.nodes;
        selectedNodes = nodes.get(ids);
        // selectedNodes.forEach((node) => {
        //    console.log(node.label.slice(0,8));
        // })
      });
  </script> -->

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { setNavigationParameter } from 'src/shared/utils';
import { SelectorChoice } from '../choice-selector/selectorDefinitions';
import { CurrencyConfigService } from '../services/currency-config.service';

interface Timings {
  address: string;
  minSubmitTime: string;
  maxSubmitTime: string;
  avgSubmitTime: string;
  name: string;
}

@Component({
  selector: 'app-timings',
  templateUrl: './timings.component.html',
  styleUrls: ['./timings.component.scss']
})

export class TimingsComponent implements OnInit {

  constructor(
    private ftsoService: FTSOService,
    private currencyConfigService: CurrencyConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.timingsData();
    console.log(this.currency)
  }

  timingsOutput: Timings[] = [];
  public choices = this.currencyConfigService.currencySelections;

  get currency() {
    let res = this.route.snapshot.queryParams["currency"];
    return res ? res : 'XRP'
  }

  goTo(selection: SelectorChoice) {
    setNavigationParameter(this.router, this.route, "currency", selection.id);
    this.timingsData();
  }

  async timingsData() {
    let ftsoCurrency = this.currencyConfigService.findAliasEntryForCurrency(this.currency)
    console.log(ftsoCurrency?.alias as string)
    let response = await this.ftsoService.getTimings(ftsoCurrency?.alias as string).pipe(take(1)).toPromise();
    let data = response.data as Timings[];
    console.log(data)

    this.timingsOutput = data;
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { BlockDetailsComponent } from './block-details/block-details.component';
import { BlocksComponent } from './blocks/blocks.component';
import { CollusionComponent } from './collusion/collusion.component';
import { ConfigComponent } from './config/config.component';
import { DataProvidersComponent } from './data-providers/data-providers.component';
import { LastRandomsComponent } from './last-randoms/last-randoms.component';
import { PriceComponent } from './price/price.component';
import { TokenActivationGuard } from './services/activate-guard.service';
import { StatsComponent } from './stats/stats.component';
import { TimingsComponent } from './timings/timings.component';
// import { VoteResultComponent } from './vote-result/vote-result.component';


const routes: Routes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'data-providers' },
  {
    path: 'price',
    component: PriceComponent,
    canActivate: [TokenActivationGuard]
  },
  {
    path: 'block',
    component: BlocksComponent,
    canActivate: [TokenActivationGuard]
  },
  {
    path: 'config',
    component: ConfigComponent,
    canActivate: [TokenActivationGuard]
  },
  {
    path: 'block/:id',
    component: BlockDetailsComponent,
    canActivate: [TokenActivationGuard]
  },
  // {
  //   path: 'vote-result/:currency/epoch-id/:epochId',
  //   component: VoteResultComponent,
  // },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [TokenActivationGuard]
  },
  {
    path: 'data-providers',
    component: DataProvidersComponent,
    canActivate: [TokenActivationGuard]
  },
  {
    path: 'timings',
    component: TimingsComponent,
    canActivate: [TokenActivationGuard]
  },
  {
    path: 'last-randoms',
    component: LastRandomsComponent,
    canActivate: [TokenActivationGuard]
  },
  {
    path: 'collusion',
    component: CollusionComponent,
    canActivate: [TokenActivationGuard]
  },
  { path: '**',
    redirectTo: 'data-providers' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

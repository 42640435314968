import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit{

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ftsoService: FTSOService,
  ) {
  }
  ngOnInit(): void {
    this.reload$.next(true);
  }

  reload$ = new BehaviorSubject<boolean>(false);

  form!: FormGroup;;

  data$ = this.reload$.pipe(
    filter(x => !!x),
    switchMap(() => this.ftsoService.getCurrentGovernanceParameters()),
    map(res => res.data),
    // tap(data => {
    //   this.form = generateFormFromMetadata(ApiUser.formMetadata(), user as ApiUser);
    // }),
    shareReplay(1)
  )

}
function generateFormFromMetadata(arg0: any, arg1: any): FormGroup {
  throw new Error('Function not implemented.');
}


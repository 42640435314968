/*
 * Copyright(c) 2009 - 2019 Abelium d.o.o.
 * Kajuhova 90, 1000 Ljubljana, Slovenia
 * All rights reserved
 * Copyright(c) 1995 - 2018 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * api-back
 * Api definition project
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the openapi-typescript-angular-generator.
 * https://github.com/alenabelium/openapi-typescript-angular-generator
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';
import { catchError }                                        from 'rxjs/operators';

import { ApiResponseAny } from '../model/apiResponseAny';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for countVotes.
 */
export namespace CountVotes {
    /**
     * Parameter map for countVotes.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for countVotes.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of countVotes
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof CountVotes.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for lastReveals.
 */
export namespace LastReveals {
    /**
     * Parameter map for lastReveals.
     */
    export interface PartialParamMap {
      address: string;
      secondsFromNow?: number;
    }

    /**
     * Enumeration of all parameters for lastReveals.
     */
    export enum Parameters {
      address = 'address',
      secondsFromNow = 'secondsFromNow'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of lastReveals
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof LastReveals.PartialParamMap]?: [string, ValidatorFn][]} = {
      address: [
              ['required', Validators.required],
      ],
      secondsFromNow: [
      ],
    };
}

/**
 * Namespace for sessionInDatabase.
 */
export namespace SessionInDatabase {
    /**
     * Parameter map for sessionInDatabase.
     */
    export interface PartialParamMap {
      sessionId: string;
    }

    /**
     * Enumeration of all parameters for sessionInDatabase.
     */
    export enum Parameters {
      sessionId = 'sessionId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of sessionInDatabase
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof SessionInDatabase.PartialParamMap]?: [string, ValidatorFn][]} = {
      sessionId: [
              ['required', Validators.required],
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class AuthService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath != null ? configuration.basePath : (basePath != null ? basePath : this.basePath);
        } else {
            this.configuration.basePath = basePath != null ? basePath : this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public countVotesByMap(
    map: CountVotes.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseAny>;
  public countVotesByMap(
    map: CountVotes.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseAny>>;
  public countVotesByMap(
    map: CountVotes.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseAny>>;
  public countVotesByMap(
    map: CountVotes.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.countVotes(
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countVotes(observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseAny>;
    public countVotes(observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseAny>>;
    public countVotes(observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseAny>>;
    public countVotes(observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseAny>(`${this.configuration.basePath}/api/auth/ftso/count-votes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'CountVotes')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public lastRevealsByMap(
    map: LastReveals.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseAny>;
  public lastRevealsByMap(
    map: LastReveals.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseAny>>;
  public lastRevealsByMap(
    map: LastReveals.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseAny>>;
  public lastRevealsByMap(
    map: LastReveals.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.lastReveals(
      map.address,
      map.secondsFromNow,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param address 
     * @param secondsFromNow 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lastReveals(address: string, secondsFromNow?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseAny>;
    public lastReveals(address: string, secondsFromNow?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseAny>>;
    public lastReveals(address: string, secondsFromNow?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseAny>>;
    public lastReveals(address: string, secondsFromNow?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (address === null || address === undefined) {
            throw new Error('Required parameter address was null or undefined when calling lastReveals.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (secondsFromNow !== undefined && secondsFromNow !== null) {
            queryParameters = queryParameters.set('secondsFromNow', <any>secondsFromNow);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseAny>(`${this.configuration.basePath}/api/auth/last-reveals/${encodeURIComponent(String(address))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'LastReveals')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sessionInDatabaseByMap(
    map: SessionInDatabase.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseAny>;
  public sessionInDatabaseByMap(
    map: SessionInDatabase.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseAny>>;
  public sessionInDatabaseByMap(
    map: SessionInDatabase.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseAny>>;
  public sessionInDatabaseByMap(
    map: SessionInDatabase.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.sessionInDatabase(
      map.sessionId,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param sessionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionInDatabase(sessionId: string, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseAny>;
    public sessionInDatabase(sessionId: string, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseAny>>;
    public sessionInDatabase(sessionId: string, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseAny>>;
    public sessionInDatabase(sessionId: string, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling sessionInDatabase.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseAny>(`${this.configuration.basePath}/api/auth/check-session/${encodeURIComponent(String(sessionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'SessionInDatabase')));
          }
        }
        return handle;
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { setNavigationParameter } from 'src/shared/utils';
import { SelectorChoice } from '../choice-selector/selectorDefinitions';
// import { runInThisContext } from 'node:vm';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }
  ngOnInit(): void {
    this.init();
  }

  public faCalendar = faCalendar
  startTime$ = new BehaviorSubject<number>(0);
  endTime$ = new BehaviorSubject<number>(Date.now());
  interval$ = new BehaviorSubject<number>(0);
  averagingPeriodSeconds$ = new BehaviorSubject<number>(10);

  // startTime: number = 0;
  // endTime: number = 0;

  get sTime() {
    let res = this.route.snapshot.queryParams["startTime"];
    return res ? res : '30m'
  }

  get eTIme() {
    let now = new Date();
    let res = this.route.snapshot.queryParams["endTime"];
    return res ? parseInt(res, 10) : Date.now()
  }

  get aps() {
    let res = this.route.snapshot.queryParams["avgPeriodSeconds"];
    // console.log("res, stats", res)
    return res ? parseInt(res, 10) : 10
  }


  changeTime(time: SelectorChoice) {
    this.interval$.next(time.value);
    this.startTime$.next(this.endTime$.value - time.value);
    setNavigationParameter(this.router, this.route, "startTime", time.id);
  }

  public init() {

    let d = new Date(0);
    d.setUTCSeconds(this.eTIme / 1000);

    this.dateModel = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    }

    this.timeModel = {
      hour: d.getHours(),
      minute: d.getMinutes(),
      second: d.getSeconds()
    }

    this.endTime$.next(this.eTIme);
    this.startTime$.next(this.endTime$.value - this.interval$.value);
    this.averagingPeriodSeconds$.next(this.aps);
    this.updateQueryParam();
  }

  public resetEndTime() {
    let now = new Date();
    this.dateModel = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    }

    this.timeModel = {
      hour: now.getHours(),
      minute: now.getMinutes(),
      second: now.getSeconds()
    }
    this.endTime$.next(Date.now());
    this.startTime$.next(this.endTime$.value - this.interval$.value);
    setNavigationParameter(this.router, this.route, "endTime");
  }

  public onDateChange(event: any) {
    let end = new Date(event.year, event.month - 1, event.day, this.timeModel.hour, this.timeModel.minute, this.timeModel.second)
    this.dateModel = event
    console.log("event", event)
    console.log("end", end)
    this.endTime$.next(end.getTime());
    this.startTime$.next(this.endTime$.value - this.interval$.value);
    setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  public onTimeChange(event: any) {
    let end = new Date(this.dateModel.year, this.dateModel.month - 1, this.dateModel.day, event.hour, event.minute, event.second)
    this.timeModel = event;
    this.endTime$.next(end.getTime());
    this.startTime$.next(this.endTime$.value - this.interval$.value);
    setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  public changeAveragingPeriodSeconds() {
    if (this.secondsModel > 0) {
      this.averagingPeriodSeconds$.next(this.secondsModel);
      setNavigationParameter(this.router, this.route, "avgPeriodSeconds", String(this.averagingPeriodSeconds$.value));
    }
  }


  async updateQueryParam() {
    // await setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
    await setNavigationParameter(this.router, this.route, "avgPeriodSeconds", String(this.averagingPeriodSeconds$.value));
  }

  timeModel: any = null;
  dateModel: any = null;
  secondsModel: any = null;

  event!: any;

  public receiveEvent(event: any) {
    this.event = event;
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GlobalEventManagerService {

    private _showLoadingSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showLoadingSpinnerEmitter: Observable<boolean> = this._showLoadingSpinner.asObservable();

    public notLoading$ = this._showLoadingSpinner.pipe(
        map(x => !x),
        shareReplay(2)
    )

    constructor(
    ) { }

    showLoading(ifShow: boolean) {
        setTimeout(() => this._showLoadingSpinner.next(ifShow), 0);
    }
}

<app-tab-nav choice="randoms"></app-tab-nav>
<div class="container">

  <div class="d-flex flex-wrap justify-content-between">
    <app-choice-selector class="mb-2" [choices]="choices"
      (selected)="goTo($event)" [initialChoiceId]="currency"></app-choice-selector>
  </div>


  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Address</th>
        <th scope="col">Name</th>
        <th scope="col">Min random length</th>
        <th scope="col">Max random length</th>
        <th scope="col">Avg random length</th>
        <th scope="col">Distinct random ratio</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let provider of randomsOutput; index as i">
        <td>{{ provider.address }}</td>
        <td>{{ provider.name }}</td>
        <td>{{ provider.minLength }}</td>
        <td>{{ provider.maxLength }}</td>
        <td>{{ provider.avgLength }}</td>
        <td>{{ provider.ratio }}</td>

      </tr>
    </tbody>
  </table>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectorChoice, SELECTORS } from './selectorDefinitions';

@Component({
  selector: 'app-choice-selector',
  templateUrl: './choice-selector.component.html',
  styleUrls: ['./choice-selector.component.scss']
})
export class ChoiceSelectorComponent implements OnInit {

  constructor() { }

  _choices: SelectorChoice[] = SELECTORS.timingDefault;

  @Input() set choices(value: SelectorChoice[]) {
    this._choices = value
    // this.initialize();
  }

  get choices(): SelectorChoice[] {
    return this._choices;
  }

  _initialChoiceId!: string;

  @Input() set initialChoiceId(value: string) {
    if(value != this._initialChoiceId) {
      this._initialChoiceId = value;
      if (this.choices.length > 0 && this._initialChoiceId) {
        let tmp = this.choices.find(x => x.id == this._initialChoiceId)
        if (tmp) {
          this._selected = tmp;
          setTimeout(() => this.selected.emit(tmp), 100);
          return;
        }
      }
    }
  }

  get initialChoiceId(): string {
    return this._initialChoiceId;
  }

  _timeChoices = SELECTORS.timingDefault;
  @Input() set timeChoices(value: SelectorChoice[]) {
    this._timeChoices = value;
    // this.initialize();
  }

  get timeChoices(): SelectorChoice[] {
    return this._timeChoices;
  }

  @Input() name = "option"
  @Input() startIndex: number = 0;

  @Input() initialChoiceTimeId?: string;
  @Output() selected = new EventEmitter<SelectorChoice>();

  public outline = true;
  public showTimes = false;
  public show = false;

  ngOnInit(): void {
    this.initialize()
    // if (this.choices.length > this.startIndex) {
    //   this._selected = this.choices[this.startIndex];
    //   setTimeout(() => this.selected.emit(this.choices[this.startIndex]), 100);
    // }
  }

  // Called on any change of certain parameters
  public initialize() {
    this.showTimes = false;
    this.show = false;

    if (this.choices.length > 0 && this.initialChoiceId) {
      this.show = true;
      let tmp = this.choices.find(x => x.id == this.initialChoiceId)
      if (tmp) {
        this._selected = tmp;
        setTimeout(() => this.selected.emit(tmp), 100);
        return;
      }
    }

    // TODO: this is not very nice way of detection
    if (this.timeChoices.length > 0 && this.timeChoices[7].id === "1d" && this.initialChoiceTimeId) {
      this.showTimes = true;
      let tmp = this.timeChoices.find(x => x.id == this.initialChoiceTimeId)
      if (tmp) {
        this._selected = tmp;
        setTimeout(() => this.selected.emit(tmp), 100);
        return;
      }
    }

    if (this.choices.length > 0 && this.initialChoiceTimeId) {
      this.show = true;
      let tmp = this.choices.find(x => x.id == this.initialChoiceTimeId)
      if (tmp) {
        this._selected = tmp;
        setTimeout(() => this.selected.emit(tmp), 100);
        return;
      }
    }

    if(this.choices.length > 0) {
      this.show = true;
    }
  }

  public _selected: SelectorChoice | null = null;
  public select(choice: SelectorChoice) {
    this.selected.emit(choice);
  }
}

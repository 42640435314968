import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { setNavigationParameter } from 'src/shared/utils';
import { DataSet, DataView } from 'vis-data';
import { Network } from 'vis-network';

import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { GlobalEventManagerService } from '../system/global-event-manager.service';


@Component({
  selector: 'app-collusion',
  templateUrl: './collusion.component.html',
  styleUrls: ['./collusion.component.scss']
})


export class CollusionComponent {

  @ViewChild('network') el!: ElementRef;

  private networkInstance: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ftsoService: FTSOService,
    private globalEventsManager: GlobalEventManagerService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ping$ = new BehaviorSubject<boolean>(false);
  timeModel: any = null;
  dateModel: any = null;
  endTime$ = new BehaviorSubject<number>(Date.now());

  showNetwork = false;

  ngOnInit(): void {
    this.init();
    this.ping$.next(true);
  }

  networkOptions = {
    nodes: {
      shape: "dot",
      color: {
        highlight: '#FF0000'
      }
    },
    physics: {
      stabilization: false,
      solver: "forceAtlas2Based"
    },
    interaction: { multiselect: true }
  };

  edgeWeights: any[] = []
  nodesView!: DataView<any>;
  edgesView!: DataView<any>;
  rawNodes!: any[];
  rawEdges!: any[];

  sub = combineLatest(this.ping$, this.endTime$)
    .pipe(
      filter(x => x[0]),
      tap(() => this.globalEventsManager.showLoading(true)),
      switchMap(pair => this.ftsoService.getVoterAnalysisData(Math.floor(pair[1]/1000))),
      tap(() => this.globalEventsManager.showLoading(false)),
      shareReplay(1)
    ).subscribe(result => {
      this.showNetwork = true;
      const container = this.el.nativeElement;
      const voterCount = result.data?.addresses!.length!;

      const matrix = result.data?.matrix!;
      const addresses = result.data?.addresses!;
      const weights = result.data?.weights!;
      const dataProviderData = result.data?.dataProviderData;

      this.rawNodes = [];
      for (let i = 0; i < voterCount; i++) {
        const address = addresses[i].toLowerCase();
        //  const info = dataProviderService.getDataProviderInfo(address)
        const info = dataProviderData![i];
        this.rawNodes.push({
          id: i + 1,
          value: weights[i],
          address: address,
          label: address.slice(0, 10) + (info && info.name ? ` (${info.name})` : "")
        })
      }

      this.edgeWeights = [];
      for (let i = 0; i < voterCount; i++) {
        for (let j = i + 1; j < voterCount; j++) {
          this.edgeWeights.push(matrix[i][j]);
        }
      }
      this.edgeWeights.sort((x, y) => x - y);

      this.rawEdges = [];
      for (let i = 0; i < voterCount; i++) {
        for (let j = i + 1; j < voterCount; j++) {
          this.rawEdges.push({
            from: i + 1,
            to: j + 1,
            value: matrix[i][j]
          })
        }
      }

      // https://tillias.wordpress.com/2020/10/11/visualize-graph-data-using-vis-network-and-angular/
      const nodes = new DataSet<any>(this.rawNodes);
      const edges = new DataSet<any>(this.rawEdges);

      this.nodesView = new DataView<any>(nodes, {});
      this.edgesView = new DataView<any>(edges, { filter: this.edgesFilter.bind(this) });
      this.onThresholdChange();
      const data = { nodes: this.nodesView, edges: this.edgesView } as any;
      this.networkInstance = new Network(container, data, this.networkOptions);

      this.networkInstance.on('click', (properties: any) => {
        var ids = properties.nodes;
        this.selectedNodes = nodes.get(ids);
      });

      this.networkInstance.once('startStabilizing', () => {
        var scaleOption = { scale: 0.3, position: {x: 0, y: 0} };
        this.networkInstance.moveTo(scaleOption);
      })


    })


  threshold = 0.97;
  thresholdValue: number = 0;
  search!: string;

  edgesFilter(edge: any) {
    return edge.value >= this.thresholdValue;
  };

  onThresholdChange(event?: any) {
    if (event == null) {
      this.threshold = 0.98;
    } else {
      this.threshold = event;
    }
    const cutoffIndex = Math.floor(this.edgeWeights.length * this.threshold);
    this.thresholdValue = this.edgeWeights[cutoffIndex];
    this.edgesView.refresh();
  }


  onSearchChange(text: any) {
    if (text && this.networkInstance) {
      let lowerText = text.toLowerCase();
      let words = lowerText.split(/[,\s]/).map((x: string) => x.trim()).filter((x: string) => x.length)
      this.selectedNodes = [];
      for(let word of words) {
        this.selectedNodes = this.selectedNodes.concat(this.rawNodes.filter(x => x.label.toLowerCase().indexOf(word) >= 0));
      }
      this.networkInstance.selectNodes(this.selectedNodes.map(x => x.id));
    }
  }

  selectedNodes: any[] = [];

  get selectedNodesCount() {
    return this.selectedNodes.length;
  }

  compareProviders() {
    if (this.selectedNodes && this.selectedNodes.length) {
      let addresses = this.selectedNodes.map(x => x.address.trim()).join(",");
      console.log(addresses);
      this.router.navigate(["/price"], {
        queryParams: {
          currency: "XRP",
          relative: true,
          providerAddress: addresses,
          endTime: Math.floor(this.endTime$.value/1000)
        }
      })
      //  let url = "https://ftso-monitor.flare.network/price?currency=XRP&startTime=30m&token=SongBird:Fly&providerAddress=" + addresses;
      //  let url = "http://localhost:4200//price?currency=XRP&startTime=30m&token=SongBird:Fly&providerAddress=" + addresses;
      //   window.open(url, '_blank').focus();
    }

  }

  get eTime() {
    let res = this.route.snapshot.queryParams["endTime"]
    return res ? parseInt(res, 10) : Date.now()
  }

  public init() {
    let d = new Date(0);
    d.setUTCSeconds(this.eTime / 1000);

    this.dateModel = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    }
    // this.timeModel = {
    //   hour: d.getHours(),
    //   minute: d.getMinutes(),
    //   second: d.getSeconds()
    // }
    this.timeModel = {
      hour: 0,
      minute: 0,
      second: 0
    }

    this.endTime$.next(this.eTime);
    // setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  public faCalendar = faCalendar;

  public onDateChange(event: any) {
    this.dateModel = event;
    this.reload();
  }

  public onTimeChange(event: any) {
    this.timeModel = event;
  }

  public resetEndTime() {
    let now = new Date();
    this.dateModel = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    }
    // console.log("month", this.dateModel.month)

    // this.timeModel = {
    //   hour: now.getHours(),
    //   minute: now.getMinutes(),
    //   second: now.getSeconds()
    // }

    this.timeModel = {
      hour: 0,
      minute: 0,
      second: 0
    }

    this.endTime$.next(Date.now())
    setNavigationParameter(this.router, this.route, "endTime");
  }

  reload() {
    this.showNetwork = false;
    let end = new Date(this.dateModel.year, this.dateModel.month - 1, this.dateModel.day, this.timeModel.hour, this.timeModel.minute, this.timeModel.second)
    this.endTime$.next(end.getTime());
    setNavigationParameter(this.router, this.route, "endTime", String(this.endTime$.value));
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub
    }
  }
}

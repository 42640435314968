
<!-- <app-tab-nav choice="config"></app-tab-nav> -->

<app-tab-nav choice="block"></app-tab-nav>
<div class="container pt-3">
  <!-- <ng-container *ngIf="timeDifferenceData$ | async as block">
    <div class="d-flex justify-content-between mb-2">
      <button type="button" class="btn btn-primary" (click)="previous()">Previous</button>
      <div class="d-flex align-items-center"><a routerLink="/block" class="mr-3">&lt;&lt; back</a><h3 class="mb-0">#{{block.number}}</h3></div>
      <button type="button" class="btn btn-primary" (click)="next()">Next</button>
    </div>

    <app-block-box [block]="block"></app-block-box>
  </ng-container>
  <div *ngIf="timeDelayHistogram$ | async as chartOptions" echarts [options]="chartOptions" class="demo-chart"></div>
  <ng-container *ngIf="timeDifferenceData$ | async as block">
    <app-transaction-box *ngFor="let transaction of block.transactions" [transaction]="transaction"></app-transaction-box>
  </ng-container> -->


  <!-- <pre *ngIf="timeDelayHistogram$ | async as txTimes">{{txTimes | json}}</pre> -->
</div>

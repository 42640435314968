import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { delay, map, shareReplay, switchMap } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';

@Component({
  selector: 'app-block-details',
  templateUrl: './block-details.component.html',
  styleUrls: ['./block-details.component.scss']
})
export class BlockDetailsComponent implements OnInit{

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ftsoService: FTSOService,
  ) {
  }
  ngOnInit(): void {
    this.reload$.next(true);
  }

  reload$ = new BehaviorSubject<boolean>(false);

  form!: FormGroup;;


//   readonly id$ = this.route.paramMap.pipe(
//     map(m => Number(m.get('id'))),
//     distinctUntilChanged()     // preprečimo emitanje, ob spremembi rute
// )


  data$ = this.route.paramMap.pipe(
    map(m => Number(m.get('id'))),
    switchMap(() => this.ftsoService.getBlockDetails(this.route.snapshot.params.id)),
    map(x => x.data),
    // tap(data => {
    //   this.form = generateFormFromMetadata(ApiUser.formMetadata(), user as ApiUser);
    // }),
    shareReplay(1)
  )

  previous() {
    this.router.navigate(['block', parseInt(this.route.snapshot.params.id) - 1 ])
  }

  next() {
    this.router.navigate(['block', parseInt(this.route.snapshot.params.id) + 1 ])
  }

  // txData$ = this.route.paramMap.pipe(
  //   map(m => Number(m.get('id'))),
  //   switchMap(() => this.ftsoService.transactionTimes(this.route.snapshot.params.id)),
  //   map(x => x.data),
  //   // tap(data => {
  //   //   this.form = generateFormFromMetadata(ApiUser.formMetadata(), user as ApiUser);
  //   // }),
  //   shareReplay(1)
  // )

  // timeDifferenceData$ = combineLatest([this.data$, this.txData$]).pipe(
  //   map(val => {
  //     let blockTimestamp = val[0].timestamp;
  //     let delays = (val[1] as any[]).map(x => blockTimestamp - x.timestamp);
  //     let gasPrices = (val[1] as any[]).map(x => blockTimestamp - x.timestamp);
  //     for(let i = 0; i < delays.length; i++) {
  //       let txdata = val[0].transactions[i];
  //       if(txdata) {
  //         txdata.delay = delays[i];
  //         txdata.gasPriceGwei = val[1][i].gasPriceGwei;
  //       }
  //     }
  //     return val[0];
  //   }),
  //   shareReplay(1)
  // )

  // timeDelayHistogram$ = this.timeDifferenceData$.pipe(
  //   map(val => {
  //     let delays = val.transactions.map((x: any) => x.delay);
  //     let max = Math.max(...delays);
  //     let min = Math.min(...delays);
  //     let mp = new Map<number, number>();
  //     delays.forEach((delay: number) => {
  //       mp.set(delay, (mp.get(delay) || 0) + 1)
  //     });
  //     let histogram = []
  //     for(let i = min; i <= max; i++) {
  //       histogram.push({
  //         name: i,
  //         value: [
  //           i,
  //           mp.get(i) || 0
  //         ]
  //       })
  //     }

  //     let options: EChartsOption = {
  //       title: {
  //         text: `Histogram of transaction delays (block.timestamp - tx.sentTime)`,
  //       },
  //       xAxis: {
  //         type: 'category',
  //         axisLabel: {
  //           formatter: '{value}s'
  //         },
  //         name: "Delay"
  //       },
  //       yAxis: [
  //         {
  //           type: 'value',
  //           min: 0,
  //           max: 'dataMax',
  //           minInterval: 1,
  //           name: "Count"
  //         }
  //       ],
  //       toolbox: {
  //         feature: {
  //           dataView: { show: true, readOnly: true },
  //           // magicType: { show: true, type: ['line', 'bar'] },
  //           // restore: { show: true },
  //           saveAsImage: { show: true, name: "PNG" }
  //         }
  //       },
  //       tooltip: {
  //         show: true,
  //         trigger: 'axis',
  //         axisPointer: {
  //           type: 'cross',
  //           crossStyle: {
  //             color: '#999'
  //           }
  //         },
  //       },
  //       dataZoom: [
  //         { type: 'slider' },
  //         { type: 'inside' }
  //       ],
  //       series: [
  //         {
  //           name: "Delay",
  //           type: 'bar',
  //           data: histogram
  //         }
  //       ],
  //     };
  //     return options;
  //   }),
  //   shareReplay(1)
  // )

}


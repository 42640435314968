/*
 * Copyright(c) 2009 - 2019 Abelium d.o.o.
 * Kajuhova 90, 1000 Ljubljana, Slovenia
 * All rights reserved
 * Copyright(c) 1995 - 2018 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * api-back
 * Api definition project
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the openapi-typescript-angular-generator.
 * https://github.com/alenabelium/openapi-typescript-angular-generator
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';
import { catchError }                                        from 'rxjs/operators';

import { ApiResponseAny } from '../model/apiResponseAny';
import { ApiResponseBlockInfoArray } from '../model/apiResponseBlockInfoArray';
import { ApiResponseCollusionAnalysisResult } from '../model/apiResponseCollusionAnalysisResult';
import { ApiResponseDataProviderStatsArray } from '../model/apiResponseDataProviderStatsArray';
import { ApiResponseEpochConfiguration } from '../model/apiResponseEpochConfiguration';
import { ApiResponseExchangePrices } from '../model/apiResponseExchangePrices';
import { ApiResponseFtsoConfigArray } from '../model/apiResponseFtsoConfigArray';
import { ApiResponseNumber } from '../model/apiResponseNumber';
import { ApiResponseNumberArrayArray } from '../model/apiResponseNumberArrayArray';
import { ApiResponsePriceDataForFtsoArray } from '../model/apiResponsePriceDataForFtsoArray';
import { ApiResponseRandomsArray } from '../model/apiResponseRandomsArray';
import { ApiResponseStringArray } from '../model/apiResponseStringArray';
import { ApiResponseSubmissionTimeForAddresses } from '../model/apiResponseSubmissionTimeForAddresses';
import { ApiResponseSuccessStatsArray } from '../model/apiResponseSuccessStatsArray';
import { ApiResponseTimingsArray } from '../model/apiResponseTimingsArray';
import { ApiResponseTransactionStatsArray } from '../model/apiResponseTransactionStatsArray';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

/**
 * Namespace for getBlockDetails.
 */
export namespace GetBlockDetails {
    /**
     * Parameter map for getBlockDetails.
     */
    export interface PartialParamMap {
      id: number;
    }

    /**
     * Enumeration of all parameters for getBlockDetails.
     */
    export enum Parameters {
      id = 'id'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getBlockDetails
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetBlockDetails.PartialParamMap]?: [string, ValidatorFn][]} = {
      id: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for getBlockInfo.
 */
export namespace GetBlockInfo {
    /**
     * Parameter map for getBlockInfo.
     */
    export interface PartialParamMap {
      startBlock?: number;
      endBlock?: number;
      startTimestamp?: number;
      endTimestamp?: number;
    }

    /**
     * Enumeration of all parameters for getBlockInfo.
     */
    export enum Parameters {
      startBlock = 'startBlock',
      endBlock = 'endBlock',
      startTimestamp = 'startTimestamp',
      endTimestamp = 'endTimestamp'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getBlockInfo
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetBlockInfo.PartialParamMap]?: [string, ValidatorFn][]} = {
      startBlock: [
      ],
      endBlock: [
      ],
      startTimestamp: [
      ],
      endTimestamp: [
      ],
    };
}

/**
 * Namespace for getCachedExchangePrice.
 */
export namespace GetCachedExchangePrice {
    /**
     * Parameter map for getCachedExchangePrice.
     */
    export interface PartialParamMap {
      currency: string;
      exchange: string;
      startTimestamp?: number;
      endTimestamp?: number;
    }

    /**
     * Enumeration of all parameters for getCachedExchangePrice.
     */
    export enum Parameters {
      currency = 'currency',
      exchange = 'exchange',
      startTimestamp = 'startTimestamp',
      endTimestamp = 'endTimestamp'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getCachedExchangePrice
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetCachedExchangePrice.PartialParamMap]?: [string, ValidatorFn][]} = {
      currency: [
              ['required', Validators.required],
      ],
      exchange: [
              ['required', Validators.required],
      ],
      startTimestamp: [
      ],
      endTimestamp: [
      ],
    };
}

/**
 * Namespace for getCurrentGovernanceParameters.
 */
export namespace GetCurrentGovernanceParameters {
    /**
     * Parameter map for getCurrentGovernanceParameters.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for getCurrentGovernanceParameters.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getCurrentGovernanceParameters
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetCurrentGovernanceParameters.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for getDataProviders.
 */
export namespace GetDataProviders {
    /**
     * Parameter map for getDataProviders.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for getDataProviders.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getDataProviders
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetDataProviders.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for getExchangePricesForCurrency.
 */
export namespace GetExchangePricesForCurrency {
    /**
     * Parameter map for getExchangePricesForCurrency.
     */
    export interface PartialParamMap {
      currency: string;
      exchange: string;
      startTimestamp?: number;
      endTimestamp?: number;
    }

    /**
     * Enumeration of all parameters for getExchangePricesForCurrency.
     */
    export enum Parameters {
      currency = 'currency',
      exchange = 'exchange',
      startTimestamp = 'startTimestamp',
      endTimestamp = 'endTimestamp'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getExchangePricesForCurrency
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetExchangePricesForCurrency.PartialParamMap]?: [string, ValidatorFn][]} = {
      currency: [
              ['required', Validators.required],
      ],
      exchange: [
              ['required', Validators.required],
      ],
      startTimestamp: [
      ],
      endTimestamp: [
      ],
    };
}

/**
 * Namespace for getExchangesForCurrency.
 */
export namespace GetExchangesForCurrency {
    /**
     * Parameter map for getExchangesForCurrency.
     */
    export interface PartialParamMap {
      currency: string;
    }

    /**
     * Enumeration of all parameters for getExchangesForCurrency.
     */
    export enum Parameters {
      currency = 'currency'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getExchangesForCurrency
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetExchangesForCurrency.PartialParamMap]?: [string, ValidatorFn][]} = {
      currency: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for getFtsoAliases.
 */
export namespace GetFtsoAliases {
    /**
     * Parameter map for getFtsoAliases.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for getFtsoAliases.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getFtsoAliases
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetFtsoAliases.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for getFtsoConfigs.
 */
export namespace GetFtsoConfigs {
    /**
     * Parameter map for getFtsoConfigs.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for getFtsoConfigs.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getFtsoConfigs
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetFtsoConfigs.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for getLastRandoms.
 */
export namespace GetLastRandoms {
    /**
     * Parameter map for getLastRandoms.
     */
    export interface PartialParamMap {
      alias: string;
    }

    /**
     * Enumeration of all parameters for getLastRandoms.
     */
    export enum Parameters {
      alias = 'alias'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getLastRandoms
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetLastRandoms.PartialParamMap]?: [string, ValidatorFn][]} = {
      alias: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for getPriceEpochConfiguration.
 */
export namespace GetPriceEpochConfiguration {
    /**
     * Parameter map for getPriceEpochConfiguration.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for getPriceEpochConfiguration.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getPriceEpochConfiguration
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetPriceEpochConfiguration.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for getPrices.
 */
export namespace GetPrices {
    /**
     * Parameter map for getPrices.
     */
    export interface PartialParamMap {
      ftsoAlias: string;
      startTimestamp?: number;
      address?: string;
      endTimestamp?: number;
      startEpochId?: number;
      endEpochId?: number;
    }

    /**
     * Enumeration of all parameters for getPrices.
     */
    export enum Parameters {
      ftsoAlias = 'ftsoAlias',
      startTimestamp = 'startTimestamp',
      address = 'address',
      endTimestamp = 'endTimestamp',
      startEpochId = 'startEpochId',
      endEpochId = 'endEpochId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getPrices
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetPrices.PartialParamMap]?: [string, ValidatorFn][]} = {
      ftsoAlias: [
              ['required', Validators.required],
      ],
      startTimestamp: [
      ],
      address: [
      ],
      endTimestamp: [
      ],
      startEpochId: [
      ],
      endEpochId: [
      ],
    };
}

/**
 * Namespace for getPricesAll.
 */
export namespace GetPricesAll {
    /**
     * Parameter map for getPricesAll.
     */
    export interface PartialParamMap {
      startTimestamp?: number;
      address?: string;
      endTimestamp?: number;
      startEpochId?: number;
      endEpochId?: number;
    }

    /**
     * Enumeration of all parameters for getPricesAll.
     */
    export enum Parameters {
      startTimestamp = 'startTimestamp',
      address = 'address',
      endTimestamp = 'endTimestamp',
      startEpochId = 'startEpochId',
      endEpochId = 'endEpochId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getPricesAll
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetPricesAll.PartialParamMap]?: [string, ValidatorFn][]} = {
      startTimestamp: [
      ],
      address: [
      ],
      endTimestamp: [
      ],
      startEpochId: [
      ],
      endEpochId: [
      ],
    };
}

/**
 * Namespace for getPricesAndStatsAddresses.
 */
export namespace GetPricesAndStatsAddresses {
    /**
     * Parameter map for getPricesAndStatsAddresses.
     */
    export interface PartialParamMap {
      alias: string;
      addresses: Array<string>;
      startTimestamp?: number;
      endTimestamp?: number;
      startEpochId?: number;
      endEpochId?: number;
    }

    /**
     * Enumeration of all parameters for getPricesAndStatsAddresses.
     */
    export enum Parameters {
      alias = 'alias',
      addresses = 'addresses',
      startTimestamp = 'startTimestamp',
      endTimestamp = 'endTimestamp',
      startEpochId = 'startEpochId',
      endEpochId = 'endEpochId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getPricesAndStatsAddresses
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetPricesAndStatsAddresses.PartialParamMap]?: [string, ValidatorFn][]} = {
      alias: [
              ['required', Validators.required],
      ],
      addresses: [
              ['required', Validators.required],
      ],
      startTimestamp: [
      ],
      endTimestamp: [
      ],
      startEpochId: [
      ],
      endEpochId: [
      ],
    };
}

/**
 * Namespace for getStats.
 */
export namespace GetStats {
    /**
     * Parameter map for getStats.
     */
    export interface PartialParamMap {
      address?: string;
      startTimestamp?: number;
      endTimestamp?: number;
      startEpochId?: number;
      endEpochId?: number;
    }

    /**
     * Enumeration of all parameters for getStats.
     */
    export enum Parameters {
      address = 'address',
      startTimestamp = 'startTimestamp',
      endTimestamp = 'endTimestamp',
      startEpochId = 'startEpochId',
      endEpochId = 'endEpochId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getStats
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetStats.PartialParamMap]?: [string, ValidatorFn][]} = {
      address: [
      ],
      startTimestamp: [
      ],
      endTimestamp: [
      ],
      startEpochId: [
      ],
      endEpochId: [
      ],
    };
}

/**
 * Namespace for getStatsAddresses.
 */
export namespace GetStatsAddresses {
    /**
     * Parameter map for getStatsAddresses.
     */
    export interface PartialParamMap {
      addresses?: Array<string>;
      alias?: string;
      startTimestamp?: number;
      endTimestamp?: number;
      startEpochId?: number;
      endEpochId?: number;
    }

    /**
     * Enumeration of all parameters for getStatsAddresses.
     */
    export enum Parameters {
      addresses = 'addresses',
      alias = 'alias',
      startTimestamp = 'startTimestamp',
      endTimestamp = 'endTimestamp',
      startEpochId = 'startEpochId',
      endEpochId = 'endEpochId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getStatsAddresses
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetStatsAddresses.PartialParamMap]?: [string, ValidatorFn][]} = {
      addresses: [
      ],
      alias: [
      ],
      startTimestamp: [
      ],
      endTimestamp: [
      ],
      startEpochId: [
      ],
      endEpochId: [
      ],
    };
}

/**
 * Namespace for getTimings.
 */
export namespace GetTimings {
    /**
     * Parameter map for getTimings.
     */
    export interface PartialParamMap {
      alias: string;
    }

    /**
     * Enumeration of all parameters for getTimings.
     */
    export enum Parameters {
      alias = 'alias'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getTimings
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetTimings.PartialParamMap]?: [string, ValidatorFn][]} = {
      alias: [
              ['required', Validators.required],
      ],
    };
}

/**
 * Namespace for getTimingsForEpochs.
 */
export namespace GetTimingsForEpochs {
    /**
     * Parameter map for getTimingsForEpochs.
     */
    export interface PartialParamMap {
      startEpochId?: number;
      endEpochId?: number;
    }

    /**
     * Enumeration of all parameters for getTimingsForEpochs.
     */
    export enum Parameters {
      startEpochId = 'startEpochId',
      endEpochId = 'endEpochId'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getTimingsForEpochs
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetTimingsForEpochs.PartialParamMap]?: [string, ValidatorFn][]} = {
      startEpochId: [
      ],
      endEpochId: [
      ],
    };
}

/**
 * Namespace for getVoterAnalysisData.
 */
export namespace GetVoterAnalysisData {
    /**
     * Parameter map for getVoterAnalysisData.
     */
    export interface PartialParamMap {
      endTime?: number;
    }

    /**
     * Enumeration of all parameters for getVoterAnalysisData.
     */
    export enum Parameters {
      endTime = 'endTime'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of getVoterAnalysisData
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof GetVoterAnalysisData.PartialParamMap]?: [string, ValidatorFn][]} = {
      endTime: [
      ],
    };
}

/**
 * Namespace for lastEpochId.
 */
export namespace LastEpochId {
    /**
     * Parameter map for lastEpochId.
     */
    export interface PartialParamMap {
    }

    /**
     * Enumeration of all parameters for lastEpochId.
     */
    export enum Parameters {
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of lastEpochId
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof LastEpochId.PartialParamMap]?: [string, ValidatorFn][]} = {
    };
}

/**
 * Namespace for transactionStats.
 */
export namespace TransactionStats {
    /**
     * Parameter map for transactionStats.
     */
    export interface PartialParamMap {
      startTimestamp?: number;
      endTimestamp?: number;
      averagingPeriodSeconds?: number;
    }

    /**
     * Enumeration of all parameters for transactionStats.
     */
    export enum Parameters {
      startTimestamp = 'startTimestamp',
      endTimestamp = 'endTimestamp',
      averagingPeriodSeconds = 'averagingPeriodSeconds'
    }

    /**
     * A map of tuples with error name and `ValidatorFn` for each parameter of transactionStats
     * that does not have an own model.
     */
    export const ParamValidators: {[K in keyof TransactionStats.PartialParamMap]?: [string, ValidatorFn][]} = {
      startTimestamp: [
      ],
      endTimestamp: [
      ],
      averagingPeriodSeconds: [
      ],
    };
}



@Injectable({
  providedIn: 'root'
})
export class FTSOService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath != null ? configuration.basePath : (basePath != null ? basePath : this.basePath);
        } else {
            this.configuration.basePath = basePath != null ? basePath : this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlockDetailsByMap(
    map: GetBlockDetails.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseAny>;
  public getBlockDetailsByMap(
    map: GetBlockDetails.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseAny>>;
  public getBlockDetailsByMap(
    map: GetBlockDetails.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseAny>>;
  public getBlockDetailsByMap(
    map: GetBlockDetails.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getBlockDetails(
      map.id,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBlockDetails(id: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseAny>;
    public getBlockDetails(id: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseAny>>;
    public getBlockDetails(id: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseAny>>;
    public getBlockDetails(id: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBlockDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseAny>(`${this.configuration.basePath}/api/ftso/block/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetBlockDetails')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlockInfoByMap(
    map: GetBlockInfo.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseBlockInfoArray>;
  public getBlockInfoByMap(
    map: GetBlockInfo.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseBlockInfoArray>>;
  public getBlockInfoByMap(
    map: GetBlockInfo.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseBlockInfoArray>>;
  public getBlockInfoByMap(
    map: GetBlockInfo.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getBlockInfo(
      map.startBlock,
      map.endBlock,
      map.startTimestamp,
      map.endTimestamp,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param startBlock 
     * @param endBlock 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBlockInfo(startBlock?: number, endBlock?: number, startTimestamp?: number, endTimestamp?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseBlockInfoArray>;
    public getBlockInfo(startBlock?: number, endBlock?: number, startTimestamp?: number, endTimestamp?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseBlockInfoArray>>;
    public getBlockInfo(startBlock?: number, endBlock?: number, startTimestamp?: number, endTimestamp?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseBlockInfoArray>>;
    public getBlockInfo(startBlock?: number, endBlock?: number, startTimestamp?: number, endTimestamp?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startBlock !== undefined && startBlock !== null) {
            queryParameters = queryParameters.set('startBlock', <any>startBlock);
        }
        if (endBlock !== undefined && endBlock !== null) {
            queryParameters = queryParameters.set('endBlock', <any>endBlock);
        }
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseBlockInfoArray>(`${this.configuration.basePath}/api/ftso/blocks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetBlockInfo')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCachedExchangePriceByMap(
    map: GetCachedExchangePrice.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseExchangePrices>;
  public getCachedExchangePriceByMap(
    map: GetCachedExchangePrice.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseExchangePrices>>;
  public getCachedExchangePriceByMap(
    map: GetCachedExchangePrice.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseExchangePrices>>;
  public getCachedExchangePriceByMap(
    map: GetCachedExchangePrice.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getCachedExchangePrice(
      map.currency,
      map.exchange,
      map.startTimestamp,
      map.endTimestamp,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param currency 
     * @param exchange 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCachedExchangePrice(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseExchangePrices>;
    public getCachedExchangePrice(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseExchangePrices>>;
    public getCachedExchangePrice(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseExchangePrices>>;
    public getCachedExchangePrice(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getCachedExchangePrice.');
        }
        if (exchange === null || exchange === undefined) {
            throw new Error('Required parameter exchange was null or undefined when calling getCachedExchangePrice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (exchange !== undefined && exchange !== null) {
            queryParameters = queryParameters.set('exchange', <any>exchange);
        }
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseExchangePrices>(`${this.configuration.basePath}/api/ftso/ftso/${encodeURIComponent(String(currency))}/exchange-prices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetCachedExchangePrice')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCurrentGovernanceParametersByMap(
    map: GetCurrentGovernanceParameters.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseAny>;
  public getCurrentGovernanceParametersByMap(
    map: GetCurrentGovernanceParameters.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseAny>>;
  public getCurrentGovernanceParametersByMap(
    map: GetCurrentGovernanceParameters.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseAny>>;
  public getCurrentGovernanceParametersByMap(
    map: GetCurrentGovernanceParameters.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getCurrentGovernanceParameters(
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentGovernanceParameters(observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseAny>;
    public getCurrentGovernanceParameters(observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseAny>>;
    public getCurrentGovernanceParameters(observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseAny>>;
    public getCurrentGovernanceParameters(observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseAny>(`${this.configuration.basePath}/api/ftso/governance-parameters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetCurrentGovernanceParameters')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDataProvidersByMap(
    map: GetDataProviders.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseDataProviderStatsArray>;
  public getDataProvidersByMap(
    map: GetDataProviders.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseDataProviderStatsArray>>;
  public getDataProvidersByMap(
    map: GetDataProviders.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseDataProviderStatsArray>>;
  public getDataProvidersByMap(
    map: GetDataProviders.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getDataProviders(
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDataProviders(observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseDataProviderStatsArray>;
    public getDataProviders(observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseDataProviderStatsArray>>;
    public getDataProviders(observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseDataProviderStatsArray>>;
    public getDataProviders(observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseDataProviderStatsArray>(`${this.configuration.basePath}/api/ftso/dataProvidersInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetDataProviders')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getExchangePricesForCurrencyByMap(
    map: GetExchangePricesForCurrency.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseNumberArrayArray>;
  public getExchangePricesForCurrencyByMap(
    map: GetExchangePricesForCurrency.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseNumberArrayArray>>;
  public getExchangePricesForCurrencyByMap(
    map: GetExchangePricesForCurrency.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseNumberArrayArray>>;
  public getExchangePricesForCurrencyByMap(
    map: GetExchangePricesForCurrency.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getExchangePricesForCurrency(
      map.currency,
      map.exchange,
      map.startTimestamp,
      map.endTimestamp,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param currency 
     * @param exchange 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExchangePricesForCurrency(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseNumberArrayArray>;
    public getExchangePricesForCurrency(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseNumberArrayArray>>;
    public getExchangePricesForCurrency(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseNumberArrayArray>>;
    public getExchangePricesForCurrency(currency: string, exchange: string, startTimestamp?: number, endTimestamp?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getExchangePricesForCurrency.');
        }
        if (exchange === null || exchange === undefined) {
            throw new Error('Required parameter exchange was null or undefined when calling getExchangePricesForCurrency.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseNumberArrayArray>(`${this.configuration.basePath}/api/ftso/ftso/${encodeURIComponent(String(currency))}/${encodeURIComponent(String(exchange))}/price`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetExchangePricesForCurrency')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getExchangesForCurrencyByMap(
    map: GetExchangesForCurrency.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseAny>;
  public getExchangesForCurrencyByMap(
    map: GetExchangesForCurrency.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseAny>>;
  public getExchangesForCurrencyByMap(
    map: GetExchangesForCurrency.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseAny>>;
  public getExchangesForCurrencyByMap(
    map: GetExchangesForCurrency.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getExchangesForCurrency(
      map.currency,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param currency 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExchangesForCurrency(currency: string, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseAny>;
    public getExchangesForCurrency(currency: string, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseAny>>;
    public getExchangesForCurrency(currency: string, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseAny>>;
    public getExchangesForCurrency(currency: string, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getExchangesForCurrency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseAny>(`${this.configuration.basePath}/api/ftso/ftso/${encodeURIComponent(String(currency))}/exchanges`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetExchangesForCurrency')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * Returns list of FTSO contract aliases
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFtsoAliasesByMap(
    map: GetFtsoAliases.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseStringArray>;
  public getFtsoAliasesByMap(
    map: GetFtsoAliases.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseStringArray>>;
  public getFtsoAliasesByMap(
    map: GetFtsoAliases.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseStringArray>>;
  public getFtsoAliasesByMap(
    map: GetFtsoAliases.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getFtsoAliases(
      observe,
      reportProgress
    );
  }


    /**
     * 
     * Returns list of FTSO contract aliases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFtsoAliases(observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseStringArray>;
    public getFtsoAliases(observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseStringArray>>;
    public getFtsoAliases(observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseStringArray>>;
    public getFtsoAliases(observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseStringArray>(`${this.configuration.basePath}/api/ftso/ftsos/aliases`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetFtsoAliases')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * Returns list of FTSO configurations
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFtsoConfigsByMap(
    map: GetFtsoConfigs.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseFtsoConfigArray>;
  public getFtsoConfigsByMap(
    map: GetFtsoConfigs.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseFtsoConfigArray>>;
  public getFtsoConfigsByMap(
    map: GetFtsoConfigs.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseFtsoConfigArray>>;
  public getFtsoConfigsByMap(
    map: GetFtsoConfigs.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getFtsoConfigs(
      observe,
      reportProgress
    );
  }


    /**
     * 
     * Returns list of FTSO configurations
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFtsoConfigs(observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseFtsoConfigArray>;
    public getFtsoConfigs(observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseFtsoConfigArray>>;
    public getFtsoConfigs(observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseFtsoConfigArray>>;
    public getFtsoConfigs(observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseFtsoConfigArray>(`${this.configuration.basePath}/api/ftso/ftsos/configs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetFtsoConfigs')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLastRandomsByMap(
    map: GetLastRandoms.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseRandomsArray>;
  public getLastRandomsByMap(
    map: GetLastRandoms.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseRandomsArray>>;
  public getLastRandomsByMap(
    map: GetLastRandoms.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseRandomsArray>>;
  public getLastRandomsByMap(
    map: GetLastRandoms.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getLastRandoms(
      map.alias,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param alias 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLastRandoms(alias: string, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseRandomsArray>;
    public getLastRandoms(alias: string, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseRandomsArray>>;
    public getLastRandoms(alias: string, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseRandomsArray>>;
    public getLastRandoms(alias: string, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling getLastRandoms.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseRandomsArray>(`${this.configuration.basePath}/api/ftso/ftso/${encodeURIComponent(String(alias))}/last-randoms`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetLastRandoms')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPriceEpochConfigurationByMap(
    map: GetPriceEpochConfiguration.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseEpochConfiguration>;
  public getPriceEpochConfigurationByMap(
    map: GetPriceEpochConfiguration.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseEpochConfiguration>>;
  public getPriceEpochConfigurationByMap(
    map: GetPriceEpochConfiguration.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseEpochConfiguration>>;
  public getPriceEpochConfigurationByMap(
    map: GetPriceEpochConfiguration.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getPriceEpochConfiguration(
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPriceEpochConfiguration(observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseEpochConfiguration>;
    public getPriceEpochConfiguration(observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseEpochConfiguration>>;
    public getPriceEpochConfiguration(observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseEpochConfiguration>>;
    public getPriceEpochConfiguration(observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseEpochConfiguration>(`${this.configuration.basePath}/api/ftso/epoch-config`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetPriceEpochConfiguration')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPricesByMap(
    map: GetPrices.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponsePriceDataForFtsoArray>;
  public getPricesByMap(
    map: GetPrices.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponsePriceDataForFtsoArray>>;
  public getPricesByMap(
    map: GetPrices.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponsePriceDataForFtsoArray>>;
  public getPricesByMap(
    map: GetPrices.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getPrices(
      map.ftsoAlias,
      map.startTimestamp,
      map.address,
      map.endTimestamp,
      map.startEpochId,
      map.endEpochId,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param ftsoAlias 
     * @param startTimestamp 
     * @param address 
     * @param endTimestamp 
     * @param startEpochId 
     * @param endEpochId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrices(ftsoAlias: string, startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponsePriceDataForFtsoArray>;
    public getPrices(ftsoAlias: string, startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponsePriceDataForFtsoArray>>;
    public getPrices(ftsoAlias: string, startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponsePriceDataForFtsoArray>>;
    public getPrices(ftsoAlias: string, startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (ftsoAlias === null || ftsoAlias === undefined) {
            throw new Error('Required parameter ftsoAlias was null or undefined when calling getPrices.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (address !== undefined && address !== null) {
            queryParameters = queryParameters.set('address', <any>address);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }
        if (startEpochId !== undefined && startEpochId !== null) {
            queryParameters = queryParameters.set('startEpochId', <any>startEpochId);
        }
        if (endEpochId !== undefined && endEpochId !== null) {
            queryParameters = queryParameters.set('endEpochId', <any>endEpochId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponsePriceDataForFtsoArray>(`${this.configuration.basePath}/api/ftso/ftso/${encodeURIComponent(String(ftsoAlias))}/prices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetPrices')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPricesAllByMap(
    map: GetPricesAll.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseAny>;
  public getPricesAllByMap(
    map: GetPricesAll.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseAny>>;
  public getPricesAllByMap(
    map: GetPricesAll.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseAny>>;
  public getPricesAllByMap(
    map: GetPricesAll.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getPricesAll(
      map.startTimestamp,
      map.address,
      map.endTimestamp,
      map.startEpochId,
      map.endEpochId,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param startTimestamp 
     * @param address 
     * @param endTimestamp 
     * @param startEpochId 
     * @param endEpochId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPricesAll(startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseAny>;
    public getPricesAll(startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseAny>>;
    public getPricesAll(startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseAny>>;
    public getPricesAll(startTimestamp?: number, address?: string, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (address !== undefined && address !== null) {
            queryParameters = queryParameters.set('address', <any>address);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }
        if (startEpochId !== undefined && startEpochId !== null) {
            queryParameters = queryParameters.set('startEpochId', <any>startEpochId);
        }
        if (endEpochId !== undefined && endEpochId !== null) {
            queryParameters = queryParameters.set('endEpochId', <any>endEpochId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseAny>(`${this.configuration.basePath}/api/ftso/ftso/prices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetPricesAll')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPricesAndStatsAddressesByMap(
    map: GetPricesAndStatsAddresses.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponsePriceDataForFtsoArray>;
  public getPricesAndStatsAddressesByMap(
    map: GetPricesAndStatsAddresses.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponsePriceDataForFtsoArray>>;
  public getPricesAndStatsAddressesByMap(
    map: GetPricesAndStatsAddresses.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponsePriceDataForFtsoArray>>;
  public getPricesAndStatsAddressesByMap(
    map: GetPricesAndStatsAddresses.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getPricesAndStatsAddresses(
      map.alias,
      map.addresses,
      map.startTimestamp,
      map.endTimestamp,
      map.startEpochId,
      map.endEpochId,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param alias 
     * @param addresses 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param startEpochId 
     * @param endEpochId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPricesAndStatsAddresses(alias: string, addresses: Array<string>, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponsePriceDataForFtsoArray>;
    public getPricesAndStatsAddresses(alias: string, addresses: Array<string>, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponsePriceDataForFtsoArray>>;
    public getPricesAndStatsAddresses(alias: string, addresses: Array<string>, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponsePriceDataForFtsoArray>>;
    public getPricesAndStatsAddresses(alias: string, addresses: Array<string>, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling getPricesAndStatsAddresses.');
        }
        if (addresses === null || addresses === undefined) {
            throw new Error('Required parameter addresses was null or undefined when calling getPricesAndStatsAddresses.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (addresses) {
            addresses.forEach((element) => {
                queryParameters = queryParameters.append('addresses', <any>element);
            })
        }
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }
        if (startEpochId !== undefined && startEpochId !== null) {
            queryParameters = queryParameters.set('startEpochId', <any>startEpochId);
        }
        if (endEpochId !== undefined && endEpochId !== null) {
            queryParameters = queryParameters.set('endEpochId', <any>endEpochId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponsePriceDataForFtsoArray>(`${this.configuration.basePath}/api/ftso/ftso/${encodeURIComponent(String(alias))}/pricesAndStats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetPricesAndStatsAddresses')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStatsByMap(
    map: GetStats.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseSuccessStatsArray>;
  public getStatsByMap(
    map: GetStats.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseSuccessStatsArray>>;
  public getStatsByMap(
    map: GetStats.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseSuccessStatsArray>>;
  public getStatsByMap(
    map: GetStats.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getStats(
      map.address,
      map.startTimestamp,
      map.endTimestamp,
      map.startEpochId,
      map.endEpochId,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param address 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param startEpochId 
     * @param endEpochId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStats(address?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseSuccessStatsArray>;
    public getStats(address?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseSuccessStatsArray>>;
    public getStats(address?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseSuccessStatsArray>>;
    public getStats(address?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (address !== undefined && address !== null) {
            queryParameters = queryParameters.set('address', <any>address);
        }
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }
        if (startEpochId !== undefined && startEpochId !== null) {
            queryParameters = queryParameters.set('startEpochId', <any>startEpochId);
        }
        if (endEpochId !== undefined && endEpochId !== null) {
            queryParameters = queryParameters.set('endEpochId', <any>endEpochId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseSuccessStatsArray>(`${this.configuration.basePath}/api/ftso/ftso/stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetStats')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStatsAddressesByMap(
    map: GetStatsAddresses.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseSuccessStatsArray>;
  public getStatsAddressesByMap(
    map: GetStatsAddresses.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseSuccessStatsArray>>;
  public getStatsAddressesByMap(
    map: GetStatsAddresses.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseSuccessStatsArray>>;
  public getStatsAddressesByMap(
    map: GetStatsAddresses.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getStatsAddresses(
      map.addresses,
      map.alias,
      map.startTimestamp,
      map.endTimestamp,
      map.startEpochId,
      map.endEpochId,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param addresses 
     * @param alias 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param startEpochId 
     * @param endEpochId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatsAddresses(addresses?: Array<string>, alias?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseSuccessStatsArray>;
    public getStatsAddresses(addresses?: Array<string>, alias?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseSuccessStatsArray>>;
    public getStatsAddresses(addresses?: Array<string>, alias?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseSuccessStatsArray>>;
    public getStatsAddresses(addresses?: Array<string>, alias?: string, startTimestamp?: number, endTimestamp?: number, startEpochId?: number, endEpochId?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (addresses) {
            addresses.forEach((element) => {
                queryParameters = queryParameters.append('addresses', <any>element);
            })
        }
        if (alias !== undefined && alias !== null) {
            queryParameters = queryParameters.set('alias', <any>alias);
        }
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }
        if (startEpochId !== undefined && startEpochId !== null) {
            queryParameters = queryParameters.set('startEpochId', <any>startEpochId);
        }
        if (endEpochId !== undefined && endEpochId !== null) {
            queryParameters = queryParameters.set('endEpochId', <any>endEpochId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseSuccessStatsArray>(`${this.configuration.basePath}/api/ftso/ftso/stats-addresses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetStatsAddresses')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTimingsByMap(
    map: GetTimings.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseTimingsArray>;
  public getTimingsByMap(
    map: GetTimings.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseTimingsArray>>;
  public getTimingsByMap(
    map: GetTimings.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseTimingsArray>>;
  public getTimingsByMap(
    map: GetTimings.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getTimings(
      map.alias,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param alias 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTimings(alias: string, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseTimingsArray>;
    public getTimings(alias: string, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseTimingsArray>>;
    public getTimings(alias: string, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseTimingsArray>>;
    public getTimings(alias: string, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {
        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling getTimings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseTimingsArray>(`${this.configuration.basePath}/api/ftso/ftso/${encodeURIComponent(String(alias))}/timings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetTimings')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTimingsForEpochsByMap(
    map: GetTimingsForEpochs.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseSubmissionTimeForAddresses>;
  public getTimingsForEpochsByMap(
    map: GetTimingsForEpochs.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseSubmissionTimeForAddresses>>;
  public getTimingsForEpochsByMap(
    map: GetTimingsForEpochs.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseSubmissionTimeForAddresses>>;
  public getTimingsForEpochsByMap(
    map: GetTimingsForEpochs.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getTimingsForEpochs(
      map.startEpochId,
      map.endEpochId,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param startEpochId 
     * @param endEpochId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTimingsForEpochs(startEpochId?: number, endEpochId?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseSubmissionTimeForAddresses>;
    public getTimingsForEpochs(startEpochId?: number, endEpochId?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseSubmissionTimeForAddresses>>;
    public getTimingsForEpochs(startEpochId?: number, endEpochId?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseSubmissionTimeForAddresses>>;
    public getTimingsForEpochs(startEpochId?: number, endEpochId?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startEpochId !== undefined && startEpochId !== null) {
            queryParameters = queryParameters.set('startEpochId', <any>startEpochId);
        }
        if (endEpochId !== undefined && endEpochId !== null) {
            queryParameters = queryParameters.set('endEpochId', <any>endEpochId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseSubmissionTimeForAddresses>(`${this.configuration.basePath}/api/ftso/ftso/timings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetTimingsForEpochs')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getVoterAnalysisDataByMap(
    map: GetVoterAnalysisData.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseCollusionAnalysisResult>;
  public getVoterAnalysisDataByMap(
    map: GetVoterAnalysisData.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseCollusionAnalysisResult>>;
  public getVoterAnalysisDataByMap(
    map: GetVoterAnalysisData.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseCollusionAnalysisResult>>;
  public getVoterAnalysisDataByMap(
    map: GetVoterAnalysisData.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.getVoterAnalysisData(
      map.endTime,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param endTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVoterAnalysisData(endTime?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseCollusionAnalysisResult>;
    public getVoterAnalysisData(endTime?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseCollusionAnalysisResult>>;
    public getVoterAnalysisData(endTime?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseCollusionAnalysisResult>>;
    public getVoterAnalysisData(endTime?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (endTime !== undefined && endTime !== null) {
            queryParameters = queryParameters.set('endTime', <any>endTime);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseCollusionAnalysisResult>(`${this.configuration.basePath}/api/ftso/ftso/voter-analysis-data`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'GetVoterAnalysisData')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * Returns last epoch id
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public lastEpochIdByMap(
    map: LastEpochId.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseNumber>;
  public lastEpochIdByMap(
    map: LastEpochId.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseNumber>>;
  public lastEpochIdByMap(
    map: LastEpochId.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseNumber>>;
  public lastEpochIdByMap(
    map: LastEpochId.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.lastEpochId(
      observe,
      reportProgress
    );
  }


    /**
     * 
     * Returns last epoch id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lastEpochId(observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseNumber>;
    public lastEpochId(observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseNumber>>;
    public lastEpochId(observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseNumber>>;
    public lastEpochId(observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseNumber>(`${this.configuration.basePath}/api/ftso/epoch/last`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'LastEpochId')));
          }
        }
        return handle;
    }


  /**
   *  by map.
   * 
   * @param map parameters map to set partial amount of parameters easily
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transactionStatsByMap(
    map: TransactionStats.PartialParamMap,
    observe?: 'body',
    reportProgress?: boolean): Observable<ApiResponseTransactionStatsArray>;
  public transactionStatsByMap(
    map: TransactionStats.PartialParamMap,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<ApiResponseTransactionStatsArray>>;
  public transactionStatsByMap(
    map: TransactionStats.PartialParamMap,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<ApiResponseTransactionStatsArray>>;
  public transactionStatsByMap(
    map: TransactionStats.PartialParamMap,
    observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    return this.transactionStats(
      map.startTimestamp,
      map.endTimestamp,
      map.averagingPeriodSeconds,
      observe,
      reportProgress
    );
  }


    /**
     * 
     * 
     * @param startTimestamp 
     * @param endTimestamp 
     * @param averagingPeriodSeconds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transactionStats(startTimestamp?: number, endTimestamp?: number, averagingPeriodSeconds?: number, observe?: 'body', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<ApiResponseTransactionStatsArray>;
    public transactionStats(startTimestamp?: number, endTimestamp?: number, averagingPeriodSeconds?: number, observe?: 'response', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpResponse<ApiResponseTransactionStatsArray>>;
    public transactionStats(startTimestamp?: number, endTimestamp?: number, averagingPeriodSeconds?: number, observe?: 'events', reportProgress?: boolean, additionalHeaders?: Array<Array<string>>): Observable<HttpEvent<ApiResponseTransactionStatsArray>>;
    public transactionStats(startTimestamp?: number, endTimestamp?: number, averagingPeriodSeconds?: number, observe: any = 'body', reportProgress: boolean = false, additionalHeaders?: Array<Array<string>>): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startTimestamp !== undefined && startTimestamp !== null) {
            queryParameters = queryParameters.set('startTimestamp', <any>startTimestamp);
        }
        if (endTimestamp !== undefined && endTimestamp !== null) {
            queryParameters = queryParameters.set('endTimestamp', <any>endTimestamp);
        }
        if (averagingPeriodSeconds !== undefined && averagingPeriodSeconds !== null) {
            queryParameters = queryParameters.set('averagingPeriodSeconds', <any>averagingPeriodSeconds);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

            if (additionalHeaders) {
                for(let pair of additionalHeaders) {
                    headers = headers.set(pair[0], pair[1]);
                }
            }

        const handle = this.httpClient.get<ApiResponseTransactionStatsArray>(`${this.configuration.basePath}/api/ftso/ftso/tps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        if(typeof this.configuration.errorHandler === 'function') {
          let errorHandler = this.configuration.errorHandler
          if(errorHandler) {   // some Typescript 'strict' shit
            return handle.pipe(catchError(err => errorHandler(err, 'TransactionStats')));
          }
        }
        return handle;
    }

}

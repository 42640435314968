// https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f

import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { Injectable } from "@angular/core";

// https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular
// glej lazy modules

@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    cachedPathsRegExList = [
      /^\/block$/,
      /^\/data-providers$/,
      /^\/collusion$/
    ] as RegExp[];

    isCached(path: any) {
      if(!path) return false;
      for(let regEx of this.cachedPathsRegExList) {
          if(regEx.test(path)) {
            return true;
          }
      }
      return false;
    }

    clearStore() {
        this.storedRouteHandles.clear()
    }

    shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return before.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (route.routeConfig!.loadChildren) return null;
        return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if(this.isCached(path)) {
            return this.storedRouteHandles.has(this.getPath(route));
        }
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if(this.isCached(path)) {
            return true;
        }
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        let pathFromParent = route.pathFromRoot.map(x => {
            if(x.url.length > 0) {
                return x.url.map(y => y.path || '').join('/')
            }
            return ''
        }).join('/')
        return pathFromParent
    }
}

<nav class="navbar navbar-dark bg-primary justify-content-start mb-3">
  <div style="width: 3.2rem; height: 3.2rem; padding: 0.1rem; margin-right: 1rem; border-radius: 50%; background-color: white">
    <svg-icon name="{{network}}-logo" [svgStyle]="{'width': '3rem', 'margin-right': '1rem'}"></svg-icon>
  </div>

  <span class="navbar-brand mb-0 h1">{{networkCapitalize}} FTSO Monitor</span>
  <div class="navbar-nav-scroll">
    <ul class="nav navbar-nav bd-navbar-nav flex-row ml-3">
      <li class="nav-item mr-3">
        <a class="nav-link" [class.active]="choice=='block'" routerLink="/block">Blocks</a>
      </li>
      <li class="nav-item mr-3">
        <a class="nav-link" [class.active]="choice=='stats'" routerLink="/stats">Block stats</a>
      </li>
      <li class="nav-item mr-3">
        <a class="nav-link" [class.active]="choice=='data-providers'" routerLink="/data-providers">Data providers</a>
      </li>
      <li class="nav-item mr-3">
        <a class="nav-link" [class.active]="choice=='price'" routerLink="/price">Prices</a>
      </li>
      <li class="nav-item mr-3">
        <a class="nav-link" [class.active]="choice=='timings'" routerLink="/timings">Timings</a>
      </li>
      <li class="nav-item mr-3">
        <a class="nav-link" [class.active]="choice=='randoms'" routerLink="/last-randoms">Last randoms</a>
      </li>
      <li class="nav-item mr-3">
        <a class="nav-link" [class.active]="choice=='collusion'" routerLink="/collusion">Collusion</a>
      </li>

      <!-- <li class="nav-item mr-3">
        <a class="nav-link" href="https://ftso-monitor-sc-team.flare.rocks/logs/" target="_blank">Logs</a>
      </li> -->
    </ul>
  </div>
  <div class="flex-fill">
  </div>
  <div>
    <select class="network-selector" [ngModel]="network" (ngModelChange)="onNetworkChange($event)">
      <option value="songbird">Songbird</option>
      <option value="flare">Flare</option>
      <option value="coston">Coston</option>
      <option value="coston2">Coston2</option>
    </select>
  </div>
  <!-- <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" [class.active]="choice=='price'" routerLink="/prices/XRP">Prices</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="choice=='block'" routerLink="/block">Blocks</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="https://ftso-monitor-sc-team.flare.rocks/logs/">Logs</a>
    </li>
  </ul> -->
</nav>

<router-outlet></router-outlet>

<div class="global-overlay-backdrop spinner-fade-in full-page-center"
    *ngIf="globalEventsManager.showLoadingSpinnerEmitter | async">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>

    <!-- <div class="spinner-border text-white" role="status">
   <span class="sr-only" i18n="spinner sporocilo|@@ui.spinner.nalagam">Nalagam...</span>
</div> -->
</div>

import { ActivatedRoute, Router } from '@angular/router';

export function DD(n: number) {
  return ('0' + n).slice(-2)
}

export function dateFormat(timestamp: number) {
  let time = new Date(timestamp * 1000);
  return `${ time.getFullYear() }-${ DD(time.getMonth() + 1) }-${ DD(time.getDate()) } ${ DD(time.getHours()) }:${ DD(time.getMinutes()) }:${ DD(time.getSeconds()) }`
}

export function prettyFormatGas(gas: number) {
  if(gas > 1000000000000) return `${Math.round(gas/10000000000)/100}T`
  if(gas > 1000000000) return `${Math.round(gas/10000000)/100}G`
  if(gas > 1000000) return `${Math.round(gas/10000)/100}M`
  if(gas > 1000) return `${Math.round(gas/10)/100}k`
  return `${gas}`
}

export async function setNavigationParameter(router: Router, route: ActivatedRoute, paramName: string, paramValue?: string | null | undefined) {
  let params = { ...route.snapshot.queryParams }

  if (paramValue) {
    params[paramName] = paramValue
  } else {
    delete params[paramName]
  }
  await router.navigate([], { relativeTo: route, queryParams: params, replaceUrl: true })
}

export async function sleepms(miliseconds: number) {
  await new Promise((resolve: any) => { setTimeout(() => { resolve() }, miliseconds) });
}
export interface EventRecord {
  timestamp: number;
  type: string;
  comment: string;
}

export interface Stats {
  coin: string,
  noCases: number;
  high: number;
  low: number;
  out: number;
  inner: number;
  border: number;
  expected: number;
  address: string;
}

export interface DataProvidersInfo {
  chainId: number;
  name: string;
  description: string;
  url: string;
  address: string;
  logoURI: string;
  availability: number;
}

export interface ColorsData {
  address: string;
  color: string;
}

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

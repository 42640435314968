import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { capitalizeFirstLetter } from 'src/shared/utils';
import { CurrencyConfigService } from '../services/currency-config.service';

@Component({
  selector: 'app-tab-nav',
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss']
})
export class TabNavComponent implements OnInit {

  @Input()
  choice = 'price'

  constructor(
    private configurationService: CurrencyConfigService
  ) { }

  ngOnInit(): void {
  }

  get networkCapitalize() {
    return capitalizeFirstLetter(this.network);
  }

  get network() {
    return this.configurationService.network;
  }

  onNetworkChange(event: string) {
    if(event === this.network) {
      return;
    }

    let link = environment.monitorLinks[event];
    if(link) {
      window.open(link,"_self")
    }
  }
}

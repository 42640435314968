import { Component, Input, OnInit } from '@angular/core';
import { dateFormat, prettyFormatGas } from 'src/shared/utils';

@Component({
  selector: 'app-block-box',
  templateUrl: './block-box.component.html',
  styleUrls: ['./block-box.component.scss']
})
export class BlockBoxComponent implements OnInit {

  @Input() public block: any;

  constructor() { }

  ngOnInit(): void {
  }

  prettyFormatGas = prettyFormatGas;

  dateFormat = dateFormat
  // dateFormat(timestamp: number) {
  //   let time = new Date(timestamp *1000);
  //   return `${ time.getFullYear() }-${ DD(time.getMonth() + 1) }-${ DD(time.getDate()) } ${ DD(time.getHours()) }:${ DD(time.getMinutes()) }:${ DD(time.getSeconds()) }`;
  // }

  isJSON = false;
  toggleJSON() {
    this.isJSON = !this.isJSON;
  }

  public revertedCount() {
    return this.block.transactions.filter((x: any) => !x.status).length
  }

}


<app-tab-nav choice="price"></app-tab-nav>

<div class="container pt-3">
  <div *ngIf="dataDisplay.address && voterAddress$.value.length == 1"
    class="d-flex justify-content-between pl-3 pb-3">
    <div class="d-flex">
      <div>
        <img src={{dataDisplay.logo}} onerror="this.style.display='none'"
          style="width:3rem;height:3rem;">
      </div>
      <div class="d-flex flex-column justify-content-center pl-3
        font-weight-bold
        provider-name">
        {{dataDisplay.name}}
      </div>
      <div *ngIf="dataDisplay.name; else elseBold" class="d-flex flex-column
        justify-content-center pl-3">
        {{dataDisplay.address}}
      </div>
      <ng-template #elseBold>
        <div class="d-flex flex-column justify-content-center pl-3
          font-weight-bold">
          Data provider: {{dataDisplay.address}}
        </div>
      </ng-template>
    </div>
    <div class="d-flex">
      <div *ngIf="expected" class="d-flex flex-column justify-content-center
        pl-3">
        Success rate: <b>{{expected}}%</b>
      </div>
      <div *ngIf="availability" class="d-flex flex-column justify-content-center
        pl-3">
        Availability: <b>{{availability}}%</b>
      </div>
    </div>
  </div>

  <div class="card mb-2 p-1">
    <app-price-votes [currency]="currency"
      [exchange]="selectedExchange"
      [exchanges]="exchanges"
      [startTime]="startTime$ | async"
      [endTime]="endTime$ | async"
      [voterAddress]="voterAddress$ | async"
      [relative]="relative"
      (stats)="getStats($event)"
      (expectedAll)="getExpected($event)"
      (availability)="getAvailability($event)"
      (colorsData)="getColors($event)"></app-price-votes>
  </div>
  <div class="d-flex flex-wrap justify-content-between">
    <app-choice-selector class="mb-2" [choices]="choices"
      (selected)="goTo($event)" [initialChoiceId]="currency"></app-choice-selector>
    <app-choice-selector class="mb-2" (selected)="changeTime($event)"
      [initialChoiceTimeId]="sTime" [timeChoices]="timeChoices"></app-choice-selector>
    <app-choice-selector *ngIf="exchanges.length" class="mb-2"
      [choices]="exchanges"
      (selected)="selectExchange($event)" [initialChoiceId]="selectedExchange"></app-choice-selector>
    <div class="custom-control custom-switch mt-2">
      <input type="checkbox" class="custom-control-input" id="switch1" [(ngModel)]="relative" (ngModelChange)="toggleRelative($event)">
      <label class="custom-control-label" for="switch1">Relative View</label>
    </div>

    <div class="row">
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group">
            <label class="mr-1"><b>End time:</b></label>
            <input class="form-control" placeholder="yyyy-mm-dd"
              name="dp" [ngModel]="dateModel" ngbDatepicker
              (ngModelChange)="onDateChange($event)" #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar"
                (click)="d.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </form>

      <ngb-timepicker class="ml-3" [ngModel]="timeModel"
        (ngModelChange)="onTimeChange($event)"></ngb-timepicker>
      <div class="d-flex flex-column justify-content-center">
        <button type="button" class="btn btn-primary ml-3"
          (click)="resetEndTime()">Reset to now</button>
      </div>

    </div>

  </div>

  <br>
  <!-- <div class="row"> -->
  <!-- <form class="form-inline"> -->
  <!-- <div class="form-group"> -->
  <!-- <div class="input-group">
          <label class="mr-1"><b>Address:</b></label>

          <input type="text" class="form-control"
            placeholder="{{voterAddress$.value}}" matInput
            name="value" [(ngModel)]="addressModel">

        </div> -->
  <!-- </div> -->
  <!-- </form> -->
  <!-- <div class="d-flex flex-column justify-content-center">
      <button type="button" class="btn btn-primary ml-3"
        (click)="changeVoterAddress()">Reset chart</button>
    </div> -->
  <!-- </div> -->
  <br>
  <div *ngIf="voterAddress$.value.length == 1">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" class="header-align">Coin</th>
          <th scope="col" class="header-align">N0. of cases</th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes strictly higher then reward belt: IQR/PCT">High(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes strictly lower then reward belt: IQR/PCT">Low(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes strictly outside of the reward belt: IQR/PCT">Out(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes on the border of the reward belt: IQR/PCT">Border(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes higher then reward belt: IQR/PCT">Inner(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Expected success rate in regard to reward belt: IQR/PCT">Expected(%)</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of statsOutput; index as i">
          <td class="value-align">{{ coinLabel(row.coin) }}</td>
          <td class="value-align">{{ row.noCases }}</td>
          <td class="value-align"><div class="iqr-value">{{ row.high }}</div><div class="pct-value">{{ row.highElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.low }}</div><div class="pct-value">{{ row.lowElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.out }}</div><div class="pct-value">{{ row.outElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.border }}</div><div class="pct-value">{{ row.borderElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.inner }}</div><div class="pct-value">{{ row.innerElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.expected }}</div><div class="pct-value">{{ row.expectedElastic }}</div></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="voterAddress$.value.length> 1">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" class="header-align"></th>
          <th scope="col" class="header-align">Address</th>
          <th scope="col" class="header-align">Number of cases</th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes strictly higher then reward belt: IQR/PCT">High(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes strictly lower then reward belt: IQR/PCT">Low(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes strictly outside of the reward belt: IQR/PCT">Out(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes on the border of the reward belt: IQR/PCT">Border(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Percentage of votes higher then reward belt: IQR/PCT">Inner(%)</span></th>
          <th scope="col" class="header-align"><span ngbTooltip="Expected success rate in regard to reward belt: IQR/PCT">Expected(%)</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of statsOutput; index as i">
          <td> <div class="color-circle" [ngStyle]="{'background-color':
              colors[i]?.color}"> </div> </td>
          <td>{{ row.address }}</td>
          <td class="value-align">{{ row.noCases }}</td>
          <td class="value-align"><div class="iqr-value">{{ row.high }}</div><div class="pct-value">{{ row.highElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.low }}</div><div class="pct-value">{{ row.lowElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.out }}</div><div class="pct-value">{{ row.outElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.border }}</div><div class="pct-value">{{ row.borderElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.inner }}</div><div class="pct-value">{{ row.innerElastic }}</div></td>
          <td class="value-align"><div class="iqr-value">{{ row.expected }}</div><div class="pct-value">{{ row.expectedElastic }}</div></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { setNavigationParameter } from 'src/shared/utils';
import { SelectorChoice } from '../choice-selector/selectorDefinitions';
import { CurrencyConfigService } from '../services/currency-config.service';

interface Randoms {
  address: string;
  minLength: string;
  maxLength: string;
  avgLength: string;
  ratio: string;
  name: string;
}


@Component({
  selector: 'app-last-randoms',
  templateUrl: './last-randoms.component.html',
  styleUrls: ['./last-randoms.component.scss']
})
export class LastRandomsComponent implements OnInit {

  constructor(
    private ftsoService: FTSOService,
    private currencyConfigService: CurrencyConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.timingsData();
    console.log(this.currency)
  }

  randomsOutput: Randoms[] = [];
  public choices = this.currencyConfigService.currencySelections;

  get currency() {
    let res = this.route.snapshot.queryParams["currency"];
    return res ? res : 'XRP'
  }

  goTo(selection: SelectorChoice) {
    setNavigationParameter(this.router, this.route, "currency", selection.id);
    this.timingsData();
  }

  async timingsData() {
    let ftsoCurrency = this.currencyConfigService.findAliasEntryForCurrency(this.currency)
    console.log(ftsoCurrency?.alias as string)
    let response = await this.ftsoService.getLastRandoms(ftsoCurrency?.alias as string).pipe(take(1)).toPromise();
    let data = response.data as Randoms[];
    console.log(data)

    this.randomsOutput = data;
  }
}

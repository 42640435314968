import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { EpochConfiguration } from 'src/api/model/epochConfiguration';
import { FtsoConfig } from 'src/api/model/ftsoConfig';
import { environment } from 'src/environments/environment';
import { SelectorChoice } from '../choice-selector/selectorDefinitions';

function currencyChoiceForLabel(label: string): SelectorChoice {
  return {
    id: label,
    label,
    value: label
  }
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyConfigService {

  private _configs!: FtsoConfig[];
  private _currencySelections: SelectorChoice[] = [];
  private _epochConfig!: EpochConfiguration;
  loaded = false;
  constructor(private ftsoService: FTSOService,) {}

  async loadConfig(): Promise<void> {
      let result = await this.ftsoService.getFtsoConfigs().pipe(take(1)).toPromise();
      this._configs = result.data!;
      this._currencySelections = this._configs.map(x => currencyChoiceForLabel(x.currency));
      let epochConfig = await this.ftsoService.getPriceEpochConfiguration().pipe(take(1)).toPromise();
      this._epochConfig = epochConfig.data!;
  }

  get ftsoConfigs(): FtsoConfig[] {
      return this._configs;
  }

  get currencySelections(){
    return this._currencySelections;
  }

  get epochConfig() {
    return this._epochConfig;
  }

  findAliasEntryForCurrency(currency: string) {
    let res = this._configs.find(x => x.currency == currency)
    return res;
  }

  get network() {
    return this.epochConfig.network;
  }
  get networkCurrency() {
    return environment.currency[this.network]
  }
}

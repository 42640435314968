<app-tab-nav choice="timings"></app-tab-nav>
<div class="container">

  <div class="d-flex flex-wrap justify-content-between">
    <app-choice-selector class="mb-2" [choices]="choices"
      (selected)="goTo($event)" [initialChoiceId]="currency"></app-choice-selector>
  </div>


  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Address</th>
        <th scope="col">Name</th>
        <th scope="col">Min submit time</th>
        <th scope="col">Max submit time</th>
        <th scope="col">Avg submit time</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let provider of timingsOutput; index as i">
        <td>{{ provider.address }}</td>
        <td>{{ provider.name }}</td>
        <td>{{ provider.minSubmitTime }}</td>
        <td>{{ provider.maxSubmitTime }}</td>
        <td>{{ provider.avgSubmitTime }}</td>
      </tr>
    </tbody>
  </table>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { FTSOService } from 'src/api/api/fTSO.service';
import { EpochConfiguration } from 'src/api/model/epochConfiguration';
import { ExchangePrices } from 'src/api/model/exchangePrices';
import { FtsoConfig } from 'src/api/model/ftsoConfig';
import { PriceDataForFtso } from 'src/api/model/priceDataForFtso';
import { SuccessStats } from 'src/api/model/successStats';
import { ColorsData, DD, Stats } from 'src/shared/utils';
import { SelectorChoice } from '../choice-selector/selectorDefinitions';
import { CurrencyConfigService } from '../services/currency-config.service';
import { GlobalEventManagerService } from '../system/global-event-manager.service';

interface addressPriceRecord {
  voterAddress: string;
  records: PriceDataForFtso[];
}

const IQR_BAND_COLOR = "#1ea5ff"
const IQR_BAND_OPACITY = 0.2
const PCT_BAND_COLOR = "#ff0000"
const PCT_BAND_OPACITY = 0.2

function isReward(record: PriceDataForFtso) {
  return record.rewardedFtso;
  // console.log(record.lowRewardedPrice)
  // let low = parseFloat(record.lowRewardedPrice);
  // return low > 0;
}

@Component({
  selector: 'app-price-votes',
  templateUrl: './price-votes.component.html',
  styleUrls: ['./price-votes.component.scss']
})
export class PriceVotes implements OnInit {

  _currency: string = 'XRP'

  @Input() set currency(value: string) {
    this._currency = value;
    this.currency$.next(value);
  }

  @Input() set startTime(value: number | null) {
    if (value != null) {
      // reset the zoom.
      this.xAxisZoom.start = 0;
      this.xAxisZoom.end = 100;
      this.startTime$.next(value)
    }
  }

  @Input() set endTime(value: number | null) {
    // reset the zoom.
    this.xAxisZoom.start = 0;
    this.xAxisZoom.end = 100;
    this.endTime$.next(value)
  }

  @Input() set voterAddress(value: string[] | null) {
    this.voterAddress$.next(value || [])
    if (value) {
      setTimeout(() => { this.selected["Voter count"] = false })
    }
  }

  @Input() set exchange(value: string | null) {
    this.exchange$.next(value || "")
  }

  @Input() exchanges: SelectorChoice[] = [];

  _relative = false;
  @Input() set relative(value: boolean) {
    this._relative = value;
    this.relative$.next(value);
  }

  get relative() {
    return this._relative;
  }

  @Output() stats = new EventEmitter<SuccessStats[]>();
  @Output() expectedAll = new EventEmitter<number>();
  @Output() availability = new EventEmitter<number>();
  @Output() colorsData = new EventEmitter<ColorsData[]>();


  constructor(
    private ftsoService: FTSOService,
    private currencyConfigService: CurrencyConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private globalEventsManager: GlobalEventManagerService
  ) {
  }



  ngOnInit(): void {
    // this.init();
    // this.chartOptions$.subscribe(console.log)
  }

  currency$ = new BehaviorSubject<string>("")
  startTime$ = new BehaviorSubject<number>(0);
  endTime$ = new BehaviorSubject<number | null>(null);
  voterAddress$ = new BehaviorSubject<string[]>([]);
  exchange$ = new BehaviorSubject<string>("");
  relative$ = new BehaviorSubject<boolean>(false);

  voteDataWithAlias$ = combineLatest(this.currency$, this.startTime$, this.voterAddress$, this.exchange$, this.relative$, this.endTime$)
    .pipe(
      filter(x => x[0] != "" && !!x[1]),
      map(pair => [this.currencyConfigService.findAliasEntryForCurrency(pair[0]), pair[1], pair[2], pair[3], pair[4], pair[5]]),
      switchMap((pair: any[]) => this.dataForAlias(pair[0], pair[1], pair[2], pair[3], pair[4], pair[5])),
      shareReplay(1)
    )



  chartOptions$ = this.voteDataWithAlias$.pipe(
    map(pair => this.optionsForAlias(pair[0] as any, pair[1] as any, pair[2] as any, pair[3] as any, pair[4] as any)),
    shareReplay(1)
  )



  showVoterPrice = false;
  showExhangeLegend = false;

  async dataForAlias(aliasEntry: FtsoConfig, startTimestamp?: number, address?: string[], exchange?: string, relative?: boolean, endTimeStamp?: number) {
    let data: PriceDataForFtso[] | undefined = undefined;
    this.globalEventsManager.showLoading(true);
    let configResponse = await this.ftsoService.getPriceEpochConfiguration().pipe(take(1)).toPromise();
    let { firstPriceEpochStartTs, priceEpochDurationSeconds } = configResponse.data as EpochConfiguration;
    // let firstPriceEpochStartTs = _firstPriceEpochStartTs;
    // let priceEpochDurationSec = _priceEpochDurationSeconds;

    if (address && address.length != 0) {
      if (address.length === 1) {
        let response = await this.ftsoService.getPrices(aliasEntry.alias, startTimestamp, address[0], endTimeStamp).pipe(take(1)).toPromise();
        data = response.data as PriceDataForFtso[];
        data.sort((a: PriceDataForFtso, b: PriceDataForFtso) => a.epochId < b.epochId ? -1 : (a.epochId > b.epochId ? 1 : 0));
        data = data.map(entry => ({
          ...entry,
          endTime: Math.floor((entry.endTime - firstPriceEpochStartTs) / priceEpochDurationSeconds) * priceEpochDurationSeconds + firstPriceEpochStartTs
        }))
      }
      else {
        let response = await this.ftsoService.getPricesAndStatsAddresses(aliasEntry.alias, address, startTimestamp, endTimeStamp).pipe(take(1)).toPromise();
        data = response.data as PriceDataForFtso[];
        data.sort((a: PriceDataForFtso, b: PriceDataForFtso) => a.epochId < b.epochId ? -1 : (a.epochId > b.epochId ? 1 : 0));
        data = data.map(entry => ({
          ...entry,
          endTime: Math.floor((entry.endTime - firstPriceEpochStartTs) / priceEpochDurationSeconds) * priceEpochDurationSeconds + firstPriceEpochStartTs
        }))
      }
    }
    else {
      let now = Math.floor(Date.now() / 1000)
      let endTime = endTimeStamp ? endTimeStamp : now;
      let k = 0
      // let response = await this.ftsoService.getPrices(aliasEntry.alias, now - 60*60*24*(k+1) + 60*60*8, undefined, now - 60*60*24*k - 60*60*8).pipe(take(1)).toPromise();
      let response = await this.ftsoService.getPrices(aliasEntry.alias, startTimestamp, undefined, endTime).pipe(take(1)).toPromise();
      data = response.data as PriceDataForFtso[];
      data.sort((a: PriceDataForFtso, b: PriceDataForFtso) => a.epochId < b.epochId ? -1 : (a.epochId > b.epochId ? 1 : 0));
      data = data.map(entry => ({
        ...entry,
        endTime: Math.floor((entry.endTime - firstPriceEpochStartTs) / priceEpochDurationSeconds) * priceEpochDurationSeconds + firstPriceEpochStartTs
      }))
    }


    this.showVoterPrice = false;
    this.showExhangeLegend = false;

    //
    if (address?.length === 1 && startTimestamp != 0) {
      let responseStats = await this.ftsoService.getStats(address[0], startTimestamp, endTimeStamp).pipe(take(1)).toPromise();
      let dataStats = responseStats.data as SuccessStats[];

      // expected for all coins
      let allCases = dataStats.reduce((accum, item) => accum + item.noCases, 0);
      let num = dataStats.reduce((accum, item) => accum + item.expected * item.noCases, 0);
      let exp = Math.round(num / allCases * 10) / 10;
      this.expectedAll.emit(exp);

      // availability
      // console.log(dataStats)
      let allEpochs = Math.round((
        ((endTimeStamp as number * 1000) || Date.now()) - (startTimestamp as number * 1000)) / (this.currencyConfigService.epochConfig.priceEpochDurationSeconds * 1000));
      let numCoins = dataStats.length;
      let numVotes = dataStats.reduce((accum, item) => accum + item.noCases, 0);
      // console.log(numVotes)
      let avb = Math.round(numVotes / (numCoins * allEpochs) * 100 * 10) / 10;
      this.availability.emit(avb);




      this.showVoterPrice = true;

      // console.log("stats", dataStats, startTimestamp)
      this.stats.emit(dataStats);
    }

    if (address && address?.length > 1 && startTimestamp != 0) {
      let responseStats = await this.ftsoService.getStatsAddresses(address, aliasEntry.alias, startTimestamp, endTimeStamp).pipe(take(1)).toPromise();
      let dataStats = responseStats.data as SuccessStats[];
      this.stats.emit(dataStats);
    }

    // ['2013/1/24', 2320.26, 2320.26, 2287.3, 2362.94]
    let candles: any[] = [];
    let exchangePrices: ExchangePrices;
    this.globalEventsManager.showLoading(true);
    if (exchange) {
      // let resCandles = await this.ftsoService.getExchangePricesForCurrency(aliasEntry.currency, exchange, startTimestamp).pipe(take(1)).toPromise()
      // candles = resCandles.data;
      candles = []

      // let resPrices = await this.ftsoService.getPricesForCurrencyAndExchanges(aliasEntry.currency, this.exchanges.map(x => x.id), startTimestamp).pipe(take(1)).toPromise()
      let resPrices = await this.ftsoService.getCachedExchangePrice(aliasEntry.currency, exchange, startTimestamp, endTimeStamp).pipe(take(1)).toPromise()
      exchangePrices = resPrices.data!;
      this.showExhangeLegend = true;
    }
    this.globalEventsManager.showLoading(false);

    return [
      aliasEntry,
      data,
      candles,
      exchangePrices!,  // can be undefined
      relative
    ];
  }


  formatter = (params: any, _ticket: any, _callback: any) => {
    if (params instanceof Array) {
      if (params.length) {
        let message = '';
        message += `<div>${params[0].axisValueLabel} (epochId: ${params[0].value[2]})</div>`;
        let theParam = params.find(param => (param.seriesName as string).endsWith('Median price in $'))
        if (theParam) {
          message += `<div class="d-flex justify-content-between"><div>Low:</div> <div class="font-weight-bold">${this.relative ? "" : '$'}${theParam.value[3]}</div></div>`
          message += `<div class="d-flex justify-content-between"><div>${theParam.marker}${theParam.seriesName}:</div> <div class="font-weight-bold">${this.relative ? "" : '$'}${theParam.value[1]}</div></div>`;
          message += `<div class="d-flex justify-content-between"><div>High: </div><div class="font-weight-bold">${this.relative ? "" : '$'}${theParam.value[4]}</div></div>`
        }

        params.forEach(param => {
          if (param.seriesName != theParam?.seriesName) {
            message += `<div class="d-flex justify-content-between"><div>${param.marker}${param.seriesName}:</div> <div class="font-weight-bold">${this.relative ? "" : '$'}${param.value[1]}</div></div>`;
          }
        });
        return message;
      } else {
        return null;
      }
    } else {
      let message = '';
      message += `${params[0].axisValueLabel}`;
      message += `<br/>${params.marker}${params.seriesName}: ${params.value}${params.data.unit || ''}`;
      return message;
    }
  };


  optionsForAlias(aliasEntry: FtsoConfig, data: PriceDataForFtso[], candles: any[], exchangePrices: ExchangePrices, relative: boolean) {
    if (!data || data.length === 0) return;
    const ipToObj = new Map<string, addressPriceRecord>()
    data.forEach((record) => {
      const rec = ipToObj.get(record.voterAddress as string)

      const outrec = { ...record };
      // delete outrec.voterAddress

      if (!rec) {
        ipToObj.set(record.voterAddress as string, {
          voterAddress: record.voterAddress as string,
          records: [outrec]
        })
      }
      else {
        rec.records.push(outrec);
      }
    });
    let data2 = [...ipToObj.values()]

    //
    let colors = ['#5470C6', '#91CC75', '#CB16CB'];
    let voterColors = ['#22162B', '#D52941', '#724E91', '#07BEB8', '#F8C630'];
    const upColor = '#ec0000';
    const upBorderColor = '#8A0000';
    const downColor = '#00da3c';
    const downBorderColor = '#008F28';
    let rewards = data2[0].records.filter(x => isReward(x)).map(x => {
      return {
        xAxis: new Date(x.endTime * 1000),
        yAxis: parseFloat(x.price) / (Math.pow(10, aliasEntry.decimals)),
        symbol: 'circle',
        silent: false,
        symbolSize: 10,
        value: "Reward",
        color: colors[0]
      }
    })

    // series
    let seriesFinal = [];

    let lowReward = {
      // z: -1,
      name: 'Low',
      type: 'line',
      data: data2[0].records.map(entry => {
        let time = new Date(entry.endTime * 1000)
        return {
          name: time,
          value: [
            `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
            (parseInt(entry.lowRewardedPrice) / Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
            entry.epochId
          ],
          epochId: entry.epochId
        } as any
      }),
      lineStyle: {
        opacity: 0
      },
      stack: 'stack',
      // areaStyle: {
      //   color: '#ccc'
      // },
      itemStyle: {
        opacity: 0
      },
      tooltip: {
        show: false
      }
    }

    let highReward = {
      // z: -1,
      // name: 'High',
      name: "IQR reward band",
      type: 'line',
      data: data2[0].records.map(entry => {
        let time = new Date(entry.endTime * 1000)
        return {
          name: time,
          value: [
            `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
            parseInt(entry.highRewardedPrice) / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1)
            - parseInt(entry.lowRewardedPrice) / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
            entry.epochId
          ],
          epochId: entry.epochId
        } as any
      }),
      lineStyle: {
        opacity: 0
      },
      itemStyle: {
        opacity: 0
      },
      areaStyle: {
        //   color: 'white',
        opacity: IQR_BAND_OPACITY,
        color: IQR_BAND_COLOR
      },
      stack: 'stack',
      tooltip: {
        show: false
      }
    }

    let lowRewardElastic = {
      // z: -1,
      name: 'Low',
      type: 'line',
      data: data2[0].records.map(entry => {
        let time = new Date(entry.endTime * 1000)
        return {
          name: time,
          value: [
            `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
            (parseInt(entry.lowElasticBandRewardPrice + '') / Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
            entry.epochId
          ],
          epochId: entry.epochId
        } as any
      }),
      lineStyle: {
        opacity: 0
      },
      stack: 'stack2',
      // areaStyle: {
      //   color: '#ccc'
      // },
      itemStyle: {
        opacity: 0
      },
      tooltip: {
        show: false
      }
    }

    let highRewardElastic = {
      // z: -1,
      // name: 'High',
      name: "Pct reward band",
      type: 'line',
      data: data2[0].records.map(entry => {
        let time = new Date(entry.endTime * 1000)
        return {
          name: time,
          value: [
            `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
            parseInt(entry.highElasticBandRewardPrice + '') / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1)
            - parseInt(entry.lowElasticBandRewardPrice + '') / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
            entry.epochId
          ],
          epochId: entry.epochId
        } as any
      }),
      lineStyle: {
        opacity: 0,
      },
      itemStyle: {
        opacity: 0
      },
      areaStyle: {
        //   color: 'white',
        opacity: PCT_BAND_OPACITY,
        color: PCT_BAND_COLOR
      },
      stack: 'stack2',
      tooltip: {
        show: false
      }
    }

    let price = {
      name: `${aliasEntry.currency} Median price in $`,
      type: 'line',
      data: data2[0].records.map(entry => {
        let time = new Date(entry.endTime * 1000)
        return {
          name: time,
          value: [
            `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
            relative ? 1 : parseInt(entry.price) / (Math.pow(10, aliasEntry.decimals)),
            entry.epochId,
            parseInt(entry.lowRewardedPrice) / (Math.pow(10, aliasEntry.decimals)),
            parseInt(entry.highRewardedPrice) / (Math.pow(10, aliasEntry.decimals))
          ],
          epochId: entry.epochId
        } as any
      }),
      markPoint: {
        data: rewards as any
      },
      itemStyle: {
        color: colors[0]
      },
      lineStyle: {
        color: colors[0],
        width: 1.5
      }
    }

    let voterCount = {
      type: 'line',
      name: "Voter count",
      data: data2[0].records.map(entry => {
        let time = new Date(entry.endTime * 1000)
        return {
          name: time,
          value: [
            `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
            entry.voteCount,
            entry.epochId
          ],
          epochId: entry.epochId
        } as any
      }),
      lineStyle: {
        color: colors[1],
        width: 1.5
      },
      itemStyle: {
        color: colors[1]
      },
      yAxisIndex: 1
    }

    // let lowPrice = {
    //   // z: -1,
    //   name: 'Min price',
    //   type: 'line',
    //   data: candles.map(entry => {
    //     let time = new Date(entry[0] * 1000)
    //     return {
    //       name: time,
    //       value: [
    //         `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
    //         entry[3]
    //       ]
    //     } as any
    //   }),
    //   lineStyle: {
    //     opacity: 0,
    //     width: 1
    //   },
    //   stack: 'stack_2',
    //   itemStyle: {
    //     opacity: 0
    //   },
    //   tooltip: {
    //     show: false
    //   }
    // }

    // let highPrice = {
    //   // z: -1,
    //   // name: 'High',
    //   name: `Price area (${this.exchange$.value})`,
    //   type: 'line',
    //   data: candles.map(entry => {
    //     let time = new Date(entry[0] * 1000)
    //     return {
    //       name: time,
    //       value: [
    //         `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
    //         entry[4] - entry[3]
    //       ]
    //     } as any
    //   }),
    //   lineStyle: {
    //     opacity: 0,
    //     width: 1
    //   },
    //   itemStyle: {
    //     opacity: 0
    //   },
    //   areaStyle: {
    //     //   color: 'white',
    //     opacity: 0.4,
    //     color: '#ff0000'
    //   },
    //   stack: 'stack_2',
    //   tooltip: {
    //     show: false
    //   }
    // }

    // let open = {
    //   // z: -1,
    //   name: `Open (${this.exchange$.value})`,
    //   type: 'line',
    //   data: candles.map(entry => {
    //     let time = new Date(entry[0] * 1000)
    //     return {
    //       name: time,
    //       value: [
    //         `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
    //         entry[1]
    //       ]
    //     } as any
    //   }),
    //   lineStyle: {
    //     opacity: 0.8,
    //     color: '#ff0000'
    //   },
    //   tooltip: {
    //     show: false
    //   }
    // }

    // let close = {
    //   // z: -1,
    //   name: `Close (${this.exchange$.value})`,
    //   type: 'line',
    //   data: candles.map(entry => {
    //     let time = new Date(entry[0] * 1000)
    //     return {
    //       name: time,
    //       value: [
    //         `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
    //         entry[2]
    //       ]
    //     } as any
    //   }),
    //   lineStyle: {
    //     opacity: 0.8,
    //     color: '#00ff00'
    //   },
    //   tooltip: {
    //     show: false
    //   }
    // }

    seriesFinal.push(
      lowReward as any,
      highReward as any,
      lowRewardElastic as any,
      highRewardElastic as any,
      price as any,
      voterCount as any
      // ,
      // lowPrice as any,
      // highPrice as any
    );

    // seriesFinal.push(open as any, close as any);

    let exSeries = {
      name: exchangePrices?.exchange,
      type: 'line',
      symbol: 'none',
      sampling: 'lttb',
      data: exchangePrices?.data ?
        exchangePrices.data.map((entry: any) => {
          let time = new Date(entry[0] * 1000)
          return {
            name: time,
            value: [
              `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
              entry[1]
            ]
          } as any
        })
        : []
    }
    if (exchangePrices?.data) {
      seriesFinal.push(exSeries);
    }


    if (data2[0].voterAddress) {
      let series = data2.map((record, index) => {
        return {
          name: `${record.voterAddress.substr(0, 8)}`,
          type: 'line',
          data: record.records.map(entry => {
            let time = new Date(entry.endTime * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                parseInt(entry.voterPrice) / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
                entry.epochId
              ],
              epochId: entry.epochId
            } as any
          }),
          itemStyle: {
            opacity: 0,
            color: voterColors[index]
          },
          lineStyle: {
            color: voterColors[index],
            width: 1
          },
          tooltip: {
            show: true
          }
        }
      })

      series.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })


      seriesFinal.push(...series as any)

      let colorAddress = data2.map((record, index) => {
        return {
          address: record.voterAddress,
          color: voterColors[index]
        }
      }) as ColorsData[]

      this.colorsData.emit(colorAddress);

      let seriesOut = data2.map((record, index) => {
        let priceOut = record.records.filter(x => x.voterPrice < x.lowRewardedPrice || x.voterPrice > x.highRewardedPrice);
        return {
          name: `${record.voterAddress.substr(0, 8)}`,
          //name: out
          type: 'scatter',
          data: priceOut.map(entry => {
            let time = new Date(entry.endTime * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                parseInt(entry.voterPrice) / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
                entry.epochId
              ],
              epochId: entry.epochId
            } as any
          }),
          symbolSize: 8,
          itemStyle: {
            color: "red"
          },
          tooltip: {
            show: false
          }
        }
      })

      seriesFinal.push(...seriesOut as any)

      let seriesIn = data2.map((record, index) => {
        let priceIn = record.records.filter(x => x.voterPrice > x.lowRewardedPrice && x.voterPrice < x.highRewardedPrice);
        return {
          name: `${record.voterAddress.substr(0, 8)}`,
          //name: out
          type: 'scatter',
          data: priceIn.map(entry => {
            let time = new Date(entry.endTime * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                parseInt(entry.voterPrice) / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
                entry.epochId
              ],
              epochId: entry.epochId
            } as any
          }),
          symbolSize: 8,
          itemStyle: {
            color: "green"
          },
          tooltip: {
            show: false
          }
        }
      })

      seriesFinal.push(...seriesIn as any)
      let seriesBorder = data2.map((record, index) => {
        let priceBorder = record.records.filter(x => x.voterPrice == x.lowRewardedPrice || x.voterPrice == x.highRewardedPrice);
        return {
          name: `${record.voterAddress.substr(0, 8)}`,
          //name: out
          type: 'scatter',
          data: priceBorder.map(entry => {
            let time = new Date(entry.endTime * 1000)
            return {
              name: time,
              value: [
                `${time.getFullYear()}-${DD(time.getMonth() + 1)}-${DD(time.getDate())} ${DD(time.getHours())}:${DD(time.getMinutes())}:${DD(time.getSeconds())}`,
                parseInt(entry.voterPrice) / (Math.pow(10, aliasEntry.decimals)) / (relative ? parseInt(entry.price) / Math.pow(10, aliasEntry.decimals) : 1),
                entry.epochId
              ],
              epochId: entry.epochId
            } as any
          }),
          symbolSize: 8,
          itemStyle: {
            color: "orange"
          },
          tooltip: {
            show: false
          }
        }
      })

      seriesFinal.push(...seriesBorder as any)

    }



    //legend
    let legendNames = [] as any;
    if (data2[0].voterAddress) {
      legendNames = data2.map((record) => {
        return `${record.voterAddress.substr(0, 8)}`
      }) as any;
      legendNames.sort()
    }

    // legendNames.push(`${aliasEntry.currency} Median price in $`, "Voter count", "Award area", this.showExhangeLegend ? `Price area (${this.exchange$.value})` : null, this.showExhangeLegend ? `Open (${this.exchange$.value})` : null, this.showExhangeLegend ? `Close (${this.exchange$.value})` : null)
    legendNames.push(
      {
        name: `${aliasEntry.currency} Median price in $`,
      },
      {
        name: "Voter count",
      },
      {
        name: "IQR reward band",
        textStyle:{
          opacity: 0.7,
          color: IQR_BAND_COLOR
        },
      },
      {
        name: "Pct reward band",
        textStyle:{
          opacity: 0.7,
          color: PCT_BAND_COLOR
        },
      }
      // ,
      // this.showExhangeLegend ? `Price area (${this.exchange$.value})` : null,
      // this.showExhangeLegend ? `Open (${this.exchange$.value})` : null,
      // this.showExhangeLegend ? `Close (${this.exchange$.value})` : null
    )

    // for(let item of exchangePrices) {
    //   legendNames.push(item.exchange)
    // }
    if (exchangePrices?.exchange) {
      legendNames.push(exchangePrices.exchange)
    }
    // legendNames.push(this.showVoterPrice ? `${aliasEntry.currency} Voter Price in $` : null)

    let options: EChartsOption = {

      grid: {
        top: '12%'
      },
      title: {
        text: `${aliasEntry.currency} Price`,
        textStyle: {
          fontSize: '12'
        }
      },
      xAxis: {
        type: 'time',
      },
      yAxis: [
        {
          type: 'value',
          name: relative ? "Price diff factor" : "Price (USD)",
          min: 'dataMin',
          max: 'dataMax',
          axisLabel: {
            formatter: relative ? '{value}' : '${value}'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
        },
        {
          type: 'value',
          name: "Number of votes",
          min: 'dataMin',
          max: 'dataMax',
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
        }
      ],
      // toolbox: {
      //   feature: {
      //     dataView: { show: true, readOnly: true },
      //     magicType: { show: true, type: ['line', 'bar'] },
      //     restore: { show: true },
      //     saveAsImage: { show: true, name: "PNG" }
      //   }
      // },
      legend: {
        data: legendNames as any,
        selected: this.selected
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        },
        formatter: this.formatter as any
      },
      dataZoom: [
        {
          type: 'slider',
          ...this.xAxisZoom
        },
        {
          type: 'inside',
          ...this.xAxisZoom
        }
      ],
      series:
        seriesFinal as any
    };
    return options;
  }

  private selected: any = {};

  private xAxisZoom: any = {};

  public clickOnData(event: any) {
    // console.log(event)
    if (event.componentType == "markPoint") {
      return;
    }
    let epochId = event.value[2];
    this.router.navigate(['vote-result', this.currency$.value, 'epoch-id', epochId])
  }


  public clickOnLegend(event: any) {
    this.selected = { ...event.selected };
    delete this.selected["undefined"]
  }

  public timelineChanged(event: any) {
    if (event.batch && event.batch.length > 0) {
      this.xAxisZoom.start = event.batch[0].start;
      this.xAxisZoom.end = event.batch[0].end;

    } else {
      this.xAxisZoom.start = event.start;
      this.xAxisZoom.end = event.end;
    }
  }

}

<app-tab-nav choice="data-providers"></app-tab-nav>
<div class="px-3">
  <div class="d-flex justify-content-between">
    <div>
      <h2>Data Providers</h2>

      <a placement="top" ngbTooltip="Show only community verified data
        providers">
        <div class="custom-control custom-switch mt-2">
          <input type="checkbox" class="custom-control-input" id="switch1"
            [(ngModel)]="listedOnly" (ngModelChange)="toggleListedOnly($event)">
          <label class="custom-control-label" for="switch1">Listed only</label>
        </div>

      </a>
    </div>
    <div class="p-2 row stat-box m-3">
      <div class="col-sm">
        <a placement="top" ngbTooltip="Reward epochs have sequential numbers.">
          <span class="stat-label">Reward epoch: </span><span
            class="stat-value">{{rewardEpoch}}</span>
        </a>
      </div>
      <div class="col-sm">
        <a placement="top" ngbTooltip="Time to start of the next reward epoch.
          Reward epoch duration is 3.5 days.">
          <span class="stat-label">Next in:</span><span class="stat-value">
            {{timeToNextRewardEpoch | durationFormat: "s" : "ddhhmm"}}</span>
        </a>
      </div>
      <div class="col-sm">
        <a placement="bottom" ngbTooltip="Total wrapped FLR that can participate
          in voting/delegation. Vote power cap per data provider is 2.5% of the
          value.">
          <span class="stat-label">Total vote power: </span><span
            class="stat-value">{{totalVotePower | formatSgbInteger}}</span>
        </a>
      </div>

    </div>
    <div>
      <button type="button" class="btn btn-primary my-2 mr-2"
        (click)="selectAllCheckboxes()">Select All</button>
      <button *ngIf="checkboxCount" type="button" class="btn btn-danger my-2
        mr-2" (click)="clearCheckboxes()">Clear ({{checkboxCount}})</button>
      <button type="button" class="btn btn-primary my-2"
        (click)="makeCompare()">Compare</button>
    </div>
  </div>

  <table
    datatable
    class="table table-striped"
    [dtOptions]="dtOptions"
    [dtTrigger]="dtTrigger">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col">Address</th>
        <th scope="col">Name</th>
        <th scope="col"><a placement="top" ngbTooltip="Average success rate over
            all signals for IQR reward band">Success rate IQR (6h)</a></th>
        <th scope="col"><a placement="top" ngbTooltip="Average success rate over
            all signals for percentage reward band">Success rate PCT (6h)</a></th>
        <th scope="col"><a placement="top" ngbTooltip="Reward in {{currency}}
            for 100 {{currency}} invested. For new reward manager in epoch 82 only.">*Reward rate</a></th>
        <th scope="col"><a placement="top" ngbTooltip="Reward in {{currency}}
            for 100 {{currency}} invested for previous reward epoch. Avail. from epoch 84 on">*Reward rate
            (prev)</a></th>
        <th scope="col">Balance ({{currency}})</th>
        <th scope="col">Availability (6h) </th>
        <th scope="col"><a placement="top" ngbTooltip="Total rewards of a data
            provider in the current price epoch. In epoch 82 for new reward manager only.">*Total reward ({{currency}})</a></th>
        <th scope="col"><a placement="top" ngbTooltip="Earnings of a data
            provider in the current price epoch (fee + own W{{currency}}
            auto-delegation). In epoch 82 for new reward manager only.">*Current earnings ({{currency}}) </a></th>
        <th scope="col"><a placement="top" ngbTooltip="Vote power at the moment.
            Includes new delegations.">Current Vote Power </a> </th>
        <th scope="col"><a placement="top" ngbTooltip="Current % of all possible
            delegations. Red means over delegation.">Current Delegation % </a>
        </th>
        <th scope="col"><a placement="top" ngbTooltip="Vote power for the
            current reward epoch">Active Vote Power </a></th>
        <th scope="col"><a placement="top" ngbTooltip="Active % of delegations
            for the running reward epoch. Red means over delegation">Active
            Delegation %</a> </th>
        <th scope="col"><a placement="top" ngbTooltip="Fee percentage and
            declaration for fee changes">Fee </a></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let control of rowsToShow.controls; index as i">
        <tr *ngIf="control.get('data')?.value as row">
          <td class="align-middle"></td>
          <td class="align-middle">
            <input type="checkbox" class="checkbox-2x"
              [formControl]="$any(control.get('checked'))" aria-label="Checkbox
              for following text input">
          </td>
          <td class="align-middle"><img src={{row.logoURI}}
              onerror="this.style.display='none'"
              style="width:50px;height:50px;"></td>
          <td class="align-middle"><a [routerLink]="['/price']"
              [queryParams]="{currency:'XRP',
              startTime:'30m', providerAddress: row.address}">{{
              row.address.slice(0, 8) }}...
            </a></td>
          <td class="align-middle"><a [routerLink]="['/price']"
              [queryParams]="{currency:'XRP',
              startTime:'30m', providerAddress: row.address}">{{ row.name }}
            </a></td>
          <ng-container *ngIf="row.successRate; else zero">
            <td class="align-middle text-right"> {{ row.successRate}}%</td>
          </ng-container>
          <ng-container *ngIf="row.successRateElastic; else zero">
            <td class="align-middle text-right"> {{ row.successRateElastic}}%</td>
          </ng-container>

          <ng-container *ngIf="row.totalEpochReward; else zero">
            <td class="align-middle text-right"> {{ rewardRate(row)}}</td>
          </ng-container>

          <ng-container *ngIf="row.totalEpochReward; else zero">
            <td class="align-middle text-right"> {{ rewardRatePrevious(row)}}</td>
          </ng-container>

          <ng-container *ngIf="row.balance; else zero">
            <td class="align-middle text-right"> {{ row.balance |
              formatSgbInteger: 2}}</td>
          </ng-container>

          <ng-container *ngIf="row.availability; else zero">
            <td class="align-middle text-right"> {{ row.availability}}%</td>
          </ng-container>

          <ng-container *ngIf="row.totalEpochReward; else zero">
            <td class="align-middle text-right"> {{ row.totalEpochReward |
              formatSgbInteger}}</td>
          </ng-container>

          <ng-container *ngIf="row.currentEpochReward; else zero">
            <td class="align-middle text-right"> {{ row.currentEpochReward |
              formatSgbInteger}}</td>
          </ng-container>

          <ng-container *ngIf="row.currentVotePower; else zero">
            <td class="align-middle text-right"> {{ row.currentVotePower |
              formatSgbInteger}}</td>
          </ng-container>
          <ng-container *ngIf="row; else zero">
            <td class="align-middle text-right"> <span
                [class]="currentDelegationClass(row)">{{ currentDelegation(row)
                }}%</span></td>
          </ng-container>

          <ng-container *ngIf="row.currentRewardEpochVotePower; else
            zeropercent">
            <td class="align-middle text-right"> {{
              row.currentRewardEpochVotePower | formatSgbInteger}}</td>
          </ng-container>
          <ng-container *ngIf="row.currentEpochReward; else zero">
            <td class="align-middle text-right"> <span
                [class]="activeDelegationClass(row)">{{ activeDelegation(row)
                }}%</span></td>
          </ng-container>
          <ng-container *ngIf="row.fee; else zero">
            <td class="align-middle text-right"> {{ row.fee | formatFee}}</td>
          </ng-container>

        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #zeropercent>
  <td class="align-middle text-right"> 0%</td>
</ng-template>
<ng-template #zero>
  <td class="align-middle text-right"> 0</td>
</ng-template>

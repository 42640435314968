import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-box',
  templateUrl: './transaction-box.component.html',
  styleUrls: ['./transaction-box.component.scss']
})
export class TransactionBoxComponent implements OnInit {

  @Input() public transaction: any;

  constructor() { }

  ngOnInit(): void {
  }

  prettyFormatGas(gas: number) {
    if (gas > 1000000) return `${ Math.round(gas / 10000) / 100 }M`
    if (gas > 1000) return `${ Math.round(gas / 10) / 100 }k`
    return `${ gas }`
  }

  isJSON = false;
  toggleJSON() {
    this.isJSON = !this.isJSON;
  }
}



import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFee'
})
export class FormatFeePipe implements PipeTransform {

  transform(value: any, decimals = 0): any {

    let fee = parseInt(value.fee)/100;
    let sch = ""
    if(value.scheduledFee) {
      let pct = parseInt(value.scheduledFee.bips)/100;
      let epoch = value.scheduledFee.fromEpoch;
      return `${fee}% (${pct}% from ${epoch})`
    } else {
      return `${fee}%`
    }
  }

}

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { ApiModule } from 'src/api/api.module';
import { Configuration } from 'src/api/configuration';
import { environment } from 'src/environments/environment';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlockBoxComponent } from './block-box/block-box.component';
import { BlockDetailsComponent } from './block-details/block-details.component';
import { BlocksChartComponent } from './blocks-chart/blocks-chart.component';
import { BlocksComponent } from './blocks/blocks.component';
import { ChoiceSelectorComponent } from './choice-selector/choice-selector.component';
import { ConfigComponent } from './config/config.component';
import { PriceVotes } from './price-votes/price-votes.component';
import { PriceComponent } from './price/price.component';
import { SelectedEventComponent } from './selected-event/selected-event.component';
import { StatsChartComponent } from './stats-chart/stats-chart.component';
import { StatsComponent } from './stats/stats.component';
import { TabNavComponent } from './tab-nav/tab-nav.component';
import { TransactionBoxComponent } from './transaction-box/transaction-box.component';
// import { VoteResultComponent } from './vote-result/vote-result.component';
import { DataProvidersComponent } from './data-providers/data-providers.component';
import { TokenInterceptor } from './system/token.interceptor';
import { TimingsComponent } from './timings/timings.component';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import { LastRandomsComponent } from './last-randoms/last-randoms.component';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { FormatSgbIntegerPipe } from './pipes/format-sgb-integer.pipe';
import { FormatFeePipe } from './pipes/format-fee.pipe';
import { DataTablesModule } from "angular-datatables";
import { CollusionComponent } from './collusion/collusion.component';
import { CurrencyConfigService } from './services/currency-config.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';


export function getConfiguration(): Configuration {
  return new Configuration({
    basePath: environment.basePath
  });
}

export function initConfig(appConfig: CurrencyConfigService) {
  return () => appConfig.loadConfig();
}
@NgModule({
  declarations: [
    AppComponent,
    PriceComponent,
    PriceVotes,
    TabNavComponent,
    BlocksChartComponent,
    BlocksComponent,
    ChoiceSelectorComponent,
    ConfigComponent,
    BlockDetailsComponent,
    TransactionBoxComponent,
    BlockBoxComponent,
    // VoteResultComponent,
    StatsComponent,
    StatsChartComponent,
    AccessDeniedComponent,
    SelectedEventComponent,
    DataProvidersComponent,
    TimingsComponent,
    LastRandomsComponent,
    FormatSgbIntegerPipe,
    FormatFeePipe,
    CollusionComponent,
    DurationFormatPipe
  ],
  imports: [
    ApiModule.forRoot(getConfiguration),
    AngularSvgIconModule.forRoot(),
		AngularSvgIconPreloaderModule.forRoot({
			configUrl: './assets/json/icons.json',
		}),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DataTablesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [CurrencyConfigService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

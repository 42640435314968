export interface SelectorChoice {
  id: string;
  label: string;
  value: any;
}

const timingChoices: SelectorChoice[] = [
  {
    id: "5m",
    label: "5m",
    value: 5 * 60 * 1000
  },
  {
    id: "15m",
    label: "15m",
    value: 15 * 60 * 1000
  },
  {
    id: "30m",
    label: "30m",
    value: 30 * 60 * 1000
  },
  {
    id: "1h",
    label: "1h",
    value: 60 * 60 * 1000
  },
  {
    id: "3h",
    label: "3h",
    value: 3 * 60 * 60 * 1000
  },
  {
    id: "6h",
    label: "6h",
    value: 6 * 60 * 60 * 1000
  },
  {
    id: "12h",
    label: "12h",
    value: 12 * 60 * 60 * 1000
  },
  {
    id: "1d",
    label: "1d",
    value: 24 * 60 * 60 * 1000
  },
  {
    id: "3d",
    label: "3d",
    value: 3 * 24 * 60 * 60 * 1000
  },
  {
    id: "1w",
    label: "1w",
    value: 7 * 24 * 60 * 60 * 1000
  }
]


const timingChoicesBlocks: SelectorChoice[] = [
  {
    id: "5m",
    label: "5m",
    value: 5*60*1000
  },
  {
    id: "15m",
    label: "15m",
    value: 15*60*1000
  },
  {
    id: "30m",
    label: "30m",
    value: 30*60*1000
  },
  {
    id: "1h",
    label: "1h",
    value: 60*60*1000
  },
  {
    id: "2h",
    label: "2h",
    value: 2*60*60*1000
  },
  {
    id: "3h",
    label: "3h",
    value: 3*60*60*1000
  },
  {
    id: "6h",
    label: "6h",
    value: 6*60*60*1000
  }
]

const timingChoicesPrices: SelectorChoice[] = [
  {
    id: "5m",
    label: "5m",
    value: 5 * 60 * 1000
  },
  {
    id: "15m",
    label: "15m",
    value: 15 * 60 * 1000
  },
  {
    id: "30m",
    label: "30m",
    value: 30 * 60 * 1000
  },
  {
    id: "1h",
    label: "1h",
    value: 60 * 60 * 1000
  },
  {
    id: "3h",
    label: "3h",
    value: 3 * 60 * 60 * 1000
  },
  {
    id: "6h",
    label: "6h",
    value: 6 * 60 * 60 * 1000
  },
  {
    id: "12h",
    label: "12h",
    value: 12 * 60 * 60 * 1000
  },
  {
    id: "1d",
    label: "1d",
    value: 24 * 60 * 60 * 1000
  },
  // {
  //   id: "2d",
  //   label: "2d",
  //   value: 2 * 24 * 60 * 60 * 1000
  // }
]


export const SELECTORS = {
  timingDefault: timingChoices,
  timingBlocks: timingChoicesBlocks,
  timingPrices: timingChoicesPrices
}

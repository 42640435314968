// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // wsEndpoint: "ws://localhost:9500",
  basePath: "",
  eventSymbolSize: 30,
  currency: {
    "flare": "FLR",
    "songbird": "SGB",
    "coston": "CFLR",
    "coston2": "C2FLR"
  } as { [key: string]: string },
  monitorLinks: {
    "flare": "https://flare-ftso-monitor.flare.network",
    "songbird": "https://songbird-ftso-monitor.flare.network",
    "coston": "https://coston-ftso-monitor.flare.network",
    "coston2": "https://coston2-ftso-monitor.flare.network",
  } as { [key: string]: string }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
